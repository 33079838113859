import { Fab, Typography, Box } from "@mui/material";
import * as React from "react";
import { forwardRef, useContext, useImperativeHandle, useState } from "react";
import { PreferencesContext } from "../../../PreferenceContext";
import { GetActivitydetail, UpdateActivityRank } from "../../../services/ActivityService";
import { GetStepdetail, UpdateStepRank } from "../../../services/StepService";
import { AlertType } from "../../../shared/AlertType";
import "./Ranking.scss";

//export default function Ranking() {
const Ranking = forwardRef((param: any, ref) => {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [rank, setRank] = useState(0);
    const [questionAnswerId, setQuestionAnswerId] = useState(0);

    useImperativeHandle(ref, () => ({
        submitClick() {
            submitData();
        },
        getData() {
            fetchData();
        }
    }),);

    function changeRank(value: number) {
        if (rank !== value) {
            setRank(value);
        } else {
            setRank(0);
        }
    }

    async function submitData() {
        if (rank > 0) {
            param.startLoading();
            if (userContext.isEditAccess === true) {
                try {
                    let data: any;

                    if (+param.curQuestion.number === 23) {
                        let requestData = {
                            hiagramUid: userContext.hiagramId,
                            questionUid: param.curQuestion.uid,
                            questionAnswerUid: questionAnswerId,
                            roleUid: userContext.roleId,
                            itemId: userContext.activityId,
                            itemStatus: null,
                            itemValue: rank.toString(),
                            isDeleted: false,
                            dataManagedBy: userContext.userId
                        }
                        data = await UpdateActivityRank(requestData);
                        if (data !== null && data.data === true) {
                            param.changeNextQuestion();
                        }
                    } else if (+param.curQuestion.number === 37) {
                        let requestData = {
                            hiagramUid: userContext.hiagramId,
                            questionUid: param.curQuestion.uid,
                            questionAnswerUid: questionAnswerId,
                            roleUid: userContext.roleId,
                            itemId: userContext.stepId,
                            itemStatus: null,
                            itemValue: rank.toString(),
                            isDeleted: false,
                            dataManagedBy: userContext.userId
                        }
                        data = await UpdateStepRank(requestData);

                        if (data !== null && data.data === true) {
                            param.changeNextQuestion();
                        }
                    }
                    param.changeNextQuestion();
                }
                catch (error: any) {
                    if (error) {
                        updateUserContext({
                            ...userContext,
                            isAlert: true,
                            alertMessage: error.response.message,
                            alertType: AlertType.Error,
                        });
                    }
                }
            } else {
                param.changeNextQuestion();
            }
        } else {
            updateUserContext({
                ...userContext,
                isAlert: true,
                alertMessage: "Please select a rank.",
                alertType: AlertType.Error,
            });
        }
    }

    async function fetchData() {
        //setShowLoder(isPageloading);
        try {
            let params = new URLSearchParams();
            params.append("huid", userContext.hiagramId.toString());
            params.append("quid", param.curQuestion.uid.toString());
            let data: any;
            if (+param.curQuestion.number === 23) {
                params.append("uid", userContext.activityId.toString());
                data = await GetActivitydetail(params);
            } else if (+param.curQuestion.number === 37) {
                params.append("uid", userContext.stepId.toString());
                data = await GetStepdetail(params);
            }

            if (data !== undefined && data !== null && data.data != null && data.data.data != null) {
                let item = data.data.data as any;
                let vRank = item.known_rating !== null ? +item.known_rating : 0;
                let questionAnswerId = item.questionAnswer.length > 0 ? +item.questionAnswer[0].uid : 0;
                setQuestionAnswerId(questionAnswerId);
                setRank(vRank);
            } else {
                setQuestionAnswerId(0);
                setRank(0);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.message,
                    alertType: AlertType.Error,
                });
            }
        }
        //setShowLoder(false);
    }

    return (
        <>
            {/* <Grid container className="main-ranking">
                <Grid item xs={2} className="fab-with-circle-grid">
                    <Fab className={rank === 1 ? "fab-circule-selected" : "fab-circule"} onClick={() => changeRank(1)}>
                        <Typography className="number-circule"><b>1</b></Typography>
                    </Fab>
                    <Typography className="text-css">Only I know this</Typography>

                </Grid>

                <Grid item xs={2} className="fab-without-circle-grid">
                    <Fab className={rank === 2 ? "fab-circule-selected" : "fab-circule"} onClick={() => changeRank(2)}>
                        <Typography className="number-circule"><b>2</b></Typography>
                    </Fab>
                    <Typography className="text-css"></Typography>
                </Grid>

                <Grid item xs={2} className="fab-with-circle-grid">
                    <Fab className={rank === 3 ? "fab-circule-selected" : "fab-circule"} onClick={() => changeRank(3)}>
                        <Typography className="number-circule"><b>3</b></Typography>
                    </Fab>
                    <Typography className="text-css-no-three">A few people understand</Typography>
                </Grid>

                <Grid item xs={2} className="fab-without-circle-grid">
                    <Fab className={rank === 4 ? "fab-circule-selected" : "fab-circule"} onClick={() => changeRank(4)}>
                        <Typography className="number-circule"><b>4</b></Typography>
                    </Fab>
                    <Typography className="text-css"></Typography>
                </Grid>

                <Grid item xs={2} className="fab-with-circle-grid">
                    <Fab className={rank === 5 ? "fab-circule-selected" : "fab-circule"} onClick={() => changeRank(5)}>
                        <Typography className="number-circule"><b>5</b></Typography>
                    </Fab>
                    <Typography className="text-css">Common knowledge</Typography>
                </Grid>
            </Grid> */}
            <Box className="main-ranking">
                <Box className="fab-with-circle-box">
                    <Fab className={rank === 1 ? "fab-circule-selected" : "fab-circule"} onClick={() => changeRank(1)}>
                        <Typography className="number-circule"><b>1</b></Typography>
                    </Fab>
                    <Typography className="text-css">Only I understand this</Typography>
                </Box>

                <Box className="fab-with-circle-box">
                    <Fab className={rank === 2 ? "fab-circule-selected" : "fab-circule"} onClick={() => changeRank(2)}>
                        <Typography className="number-circule"><b>2</b></Typography>
                    </Fab>
                    <Typography className="text-css">Very few people understand this.</Typography>
                </Box>
                <Box className="fab-with-circle-box">
                    <Fab className={rank === 3 ? "fab-circule-selected" : "fab-circule"} onClick={() => changeRank(3)}>
                        <Typography className="number-circule"><b>3</b></Typography>
                    </Fab>
                    <Typography className="text-css">A few people understand</Typography>
                </Box>
                <Box className="fab-with-circle-box">
                    <Fab className={rank === 4 ? "fab-circule-selected" : "fab-circule"} onClick={() => changeRank(4)}>
                        <Typography className="number-circule"><b>4</b></Typography>
                    </Fab>
                    <Typography className="text-css">Most people understand this.</Typography>
                </Box>
                <Box className="fab-with-circle-box">
                    <Fab className={rank === 5 ? "fab-circule-selected" : "fab-circule"} onClick={() => changeRank(5)}>
                        <Typography className="number-circule"><b>5</b></Typography>
                    </Fab>
                    <Typography className="text-css">Commonly understood</Typography>
                </Box>
            </Box>

        </>
    );
});
export default Ranking;


import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import { PreferencesContext } from "../../../PreferenceContext";
import { GetQuestionAnswer, UpdateQuestionAnswer } from "../../../services/QuestionService";
import { AlertType } from "../../../shared/AlertType";


const Checkpoint = forwardRef((param: any, ref) => {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [curAnswerId, setCurAnswerId] = useState(0);

    useImperativeHandle(ref, () => ({
        submitClick() {
            saveAnswer();
        },
        getData() {
            fetchData();
        }
    }),);

    async function fetchData() {
        try {
            let data: any;
            let params = new URLSearchParams();
            params.append("huid", userContext.hiagramId.toString());
            params.append("quid", param.curQuestion.uid.toString());
            // if (+param.curQuestion.number === 44) {
            // hp code start
            if (+param.curQuestion.number === 44 || +param.curQuestion.number === 48) {
                // hp code end

                params.append("auid", userContext.activityId.toString());
            } else if (+param.curQuestion.number === 46) {
                params.append("ruid", userContext.roleId.toString());
            } else if (+param.curQuestion.number === 47) {
                if (userContext.isProj.toString() === "true") {
                    params.append("puid", userContext.teamProjId.toString());
                } else {
                    params.append("tuid", userContext.teamProjId.toString());
                }
            }
            data = await GetQuestionAnswer(params);
            debugger
            if (+param.curQuestion.number === 44) {
                let lqInfo: any = localStorage.getItem('lQ_Info')
                if (lqInfo) {
                    lqInfo = JSON.parse(lqInfo);
                    if (lqInfo?.activityId?.toString() === data?.data?.data?.activity_uid?.toString() ) {
                        updateUserContext({
                            ...userContext,
                            isProj: lqInfo?.isProj || userContext.isProj,
                            teamProjId: lqInfo?.teamProjId || userContext.teamProjId,
                            activityId: lqInfo?.activityId || userContext.activityId,
                            activityName: lqInfo?.activityName || userContext.activityName,
                            teamProjName: lqInfo?.teamProjName || userContext.teamProjName,
                            teamProjPurpose: lqInfo?.teamProjPurpose || userContext.teamProjPurpose,
                            roleId: lqInfo?.roleId || userContext.roleId,
                            roleName: lqInfo?.roleName || userContext.roleName,
                            hiagramProgress: lqInfo?.hiagramProgress || userContext.hiagramProgress,
                            //  teamProjName: lqInfo?.teamProjName || userContext.teamProjName,
                            // activityName: lqInfo?.activityName || userContext.activityName,
                        })
                    }
                    localStorage.removeItem('lQ_Info')
                }
            }
            if (data !== undefined && data !== null && data.data !== null && data.data.data !== null) {
                setCurAnswerId(+data.data.data.uid);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }

    async function saveAnswer() {
        param.startLoading();
        // debugger
        if (userContext.isEditAccess === true) {
            try {
                let requestData: any = {
                    hiagramUid: userContext.hiagramId,
                    questionUid: param.curQuestion.uid,
                    questionAnswerUid: curAnswerId,
                    appendType: "CHECKPOINT",
                    teamUid: null,
                    projUid: null,
                    roleUid: null,
                    activityUid: null,
                    cessUid: null,
                    isDeleted: false,
                    dataManagedBy: userContext.userId
                }
                let data: any;
                // if (+param.curQuestion.number === 44) {
                // hp code start  48 free version new question
                if (+param.curQuestion.number === 44 || +param.curQuestion.number === 48) {
                    // hp code end
                    requestData.activityUid = userContext.activityId;
                    // if (+param.curQuestion.number === 44) {
                    //     let lastQuestionInfo = { isProj: userContext.isProj, activityName: userContext.activityName, teamProjName: userContext.teamProjName }
                    //     localStorage.setItem('lQ_Info', JSON.stringify(lastQuestionInfo))
                    // }
                    data = await UpdateQuestionAnswer(requestData);
                    if (data !== null && data.data === true) {
                        param.changeNextQuestion();
                    }
                } else if (+param.curQuestion.number === 46) {
                    requestData.roleUid = userContext.roleId;

                    data = await UpdateQuestionAnswer(requestData);
                    if (data !== null && data.data === true) {
                        param.changeNextQuestion();
                    }
                } else if (+param.curQuestion.number === 47) {
                    if (userContext.isProj.toString() === "true") {
                        requestData.projUid = userContext.teamProjId;
                    } else {
                        requestData.teamUid = userContext.teamProjId;
                    }
                    data = await UpdateQuestionAnswer(requestData);
                    if (data !== null && data.data === true) {
                        param.changeNextQuestion();
                    }
                }

            } catch (error: any) {
                if (error) {
                    updateUserContext({
                        ...userContext,
                        isAlert: true,
                        alertMessage: error.response.message,
                        alertType: AlertType.Error,
                    });
                }
            }
        } else {
            param.changeNextQuestion();
        }
    }
    return (<></>);
});

export default Checkpoint;
import { Grid, TextField, Button, Typography, Box, useTheme, CircularProgress, Tooltip, Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle } from "@mui/material";
import deleteicon from "../../../assets/icons/trash.svg";
import AddIcon from '@mui/icons-material/Add';
import tickicon from "../../../assets/icons/tick.svg";
import * as React from "react";
import "./StandardMany.scss";
import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";
import { Form, Formik } from "formik";
import { CreateTeam, GetTeamList } from "../../../services/TeamService";
import { AlertType } from "../../../shared/AlertType";
import { PreferencesContext } from "../../../PreferenceContext";
import { CreateProject, GetProjectList } from "../../../services/ProjectService";
import { CreateRole, GetRoleList } from "../../../services/RoleService";
import { CreateActivity, GetActivityList, GetRaciList } from "../../../services/ActivityService";
import { CreateCess, GetCessList } from "../../../services/CessService";
import { getSingleSelected } from "../../../services/common/commonFunction";
import Select from "react-select";
import { LoadingButton } from "@mui/lab";
import OfflineBoltOutlinedIcon from '@mui/icons-material/OfflineBoltOutlined';
import ReplayIcon from '@mui/icons-material/Replay';
import { RephraseText } from "../../../services/OpenAIService";
import { CUR_ENV } from "../../../services/common/const";
// import userImage from '../../../assets/icons/tick-circle.svg'
import HelpIcon from '@mui/icons-material/Help';

interface IItemDetail {
  rowNo: number;
  itemId: number;
  iteamAnswerId: number;
  itemOldValue: String;
  itemValue: String;
  raciOld: String;
  raci: String;
  isFocused: boolean;
  isBtnVisible: boolean;
  isLoading: boolean;
  isDeleting: boolean;
  isDeleted: boolean;
}

interface IItem {
  itemDetailCollection: [IItemDetail];
}

const initItemDetailData: IItemDetail = {
  rowNo: 0,
  itemId: 0,
  iteamAnswerId: 0,
  itemOldValue: "",
  itemValue: "",
  raciOld: "",
  raci: "",
  isFocused: false,
  isLoading: false,
  isDeleted: false,
  isBtnVisible: false,
  isDeleting: false
};

const initialItemData: IItem = {
  itemDetailCollection: [initItemDetailData],
};

// export default function StandardMany() {
const StandardMany = forwardRef((param: any, ref) => {
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  const [itemData, setItemData] = useState(initialItemData);
  const [isDisabled, setDisabled] = React.useState(false);
  const [raciData, setRaciData] = React.useState([]);
  const [isRaciDisabled, setRaciDisabled] = React.useState(false);
  const mainTheme = useTheme();
  const [isLoading, setLoading] = useState(false);
  const [gptAnswers, setGptAnswers] = useState([""]);
  const [curIndex, setCurIndex] = useState(0);
  const [isAIBoxVisible, setAIBoxVisible] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [curRow, setCurRow] = useState(-1);
  const [contWarningModel, setContWarningModel] = useState(false)
  const [warningShowMessage, setWarningShowMessage] = useState('')
  const [isSaveBtnVisiable, setIsSaveBtnVisiable] = useState(false)
  const [othersDataCount, setOthersDataCount] = useState(0)
  useImperativeHandle(ref, () => ({
    submitClick() {
      let element: HTMLElement = document.getElementsByClassName('inner-button')[0] as HTMLElement;
      element.click();
      //param.changeNextQuestion();
    },
    getData() {
      fetchData();
      if (+userContext.tempUid !== 3) {
        fetchRACI();
      }
      getdefaultDescription(true);
    }
  }),);

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  }

  const handleOpenDeleteModal = (row: number) => {
    setCurRow(row);
    setDeleteModal(true);
  }

  const handleCloseWelcomeModal = () => {
    setContWarningModel(false);
  }
  const handleAddNewRow = () => {
    let detailColl: any = itemData.itemDetailCollection;
    if (param.curQuestion.append_to.toUpperCase() === "ACTIVITY") {
      if (detailColl.length === 3) {
        setContWarningModel(true)
        setWarningShowMessage("You’ve already added 3 roles. You’ll be able to reduce your interview time if you can condense these into broader roles.")
      } else if (detailColl.length === 7) {
        setContWarningModel(true)
        setWarningShowMessage("7 roles already and you’re about to add another! Take a quick look at the guidance - you may be able to reduce your interview time by condensing your roles into broader categories.")
      }
    } else if (param.curQuestion.append_to.toUpperCase() === "TEAM") {
      if (detailColl.length + othersDataCount === 5) {
        setContWarningModel(true)
        setWarningShowMessage("You’ve already added five teams/projects. Are you sure you’re not confusing the Teams you’re in with the Jobs you do? Take a look at the guidance just in case - you may be able to reduce your interview time.")
      } else if (detailColl.length + othersDataCount === 10) {
        setContWarningModel(true)
        setWarningShowMessage("Ten teams/projects already and you’re about to add another! Take a quick look at the guidance if you haven’t already - you may be able to reduce your interview time.")
      }
    } else if (param.curQuestion.append_to.toUpperCase() === "PROJ") {
      if (detailColl.length + othersDataCount === 5) {
        setContWarningModel(true)
        setWarningShowMessage("You’ve already added five teams/projects. Are you sure you’re not confusing the Teams you’re in with the Jobs you do? Take a look at the guidance just in case - you may be able to reduce your interview time.")

      } else if (detailColl.length + othersDataCount === 10) {
        setContWarningModel(true)
        setWarningShowMessage("Ten teams/projects already and you’re about to add another! Take a quick look at the guidance if you haven’t already - you may be able to reduce your interview time.")
      }
    }
    const rowNos = detailColl.map((o: any) => {
      return o.rowNo;
    });
    detailColl.forEach((element: any) => {
      element.isFocused = false;
    });
    const maxRowNo = Math.max(...rowNos);

    let newRowNo = maxRowNo + 1;

    let newDataRow: IItemDetail = {
      rowNo: newRowNo,
      itemId: 0,
      iteamAnswerId: 0,
      itemOldValue: "",
      itemValue: "",
      raciOld: "",
      raci: "",
      isFocused: false,
      isLoading: false,
      isDeleted: false,
      isBtnVisible: false,
      isDeleting: false
    };

    detailColl.push(newDataRow);
    setItemData({ ...itemData, itemDetailCollection: detailColl });

  };

  // const handleDeleteRow = (rowNumber: number) => {
  //   let detailColl = itemData.itemDetailCollection;
  //   detailColl.forEach(element => {
  //     element.isFocused = false;
  //   });
  //   detailColl[rowNumber].isDeleted = true;

  //   if (detailColl.filter((x) => x.isDeleted === false).length === 0) {
  //     handleAddNewRow();
  //   }
  //   setItemData({ ...itemData, itemDetailCollection: detailColl });
  // };

  const handleDeleteRow = (rowNumber: number) => {
    let detailColl = itemData.itemDetailCollection;
    detailColl.forEach(element => {
      element.isFocused = false;
    });
    detailColl[rowNumber].isDeleted = true;

    if (detailColl.filter((x) => x.isDeleted === false).length === 0) {
      handleAddNewRow();
    }
    setItemData({ ...itemData, itemDetailCollection: detailColl });
  };

  const handleItemDetailChange = (props: any, rowNumber: number, obj: any, type: any) => {
    // props.values.itemDetailCollection[dataRow.rowNo].isBtnVisible
    // let detailColl = itemData.itemDetailCollection;
    let detailColl = props.values.itemDetailCollection
    let currentRow = detailColl[rowNumber];
    if (currentRow) {
      if (type === 1) {
        if (+userContext.tempUid === 3) {
          currentRow.itemValue = obj.target.value !== null ? obj.target.value : ""
          currentRow.raci = obj.value !== null ? obj.value : ""
        } else {
          currentRow.itemValue = obj.target.value !== null ? obj.target.value : ""
        }
      } else if (type === 2) {
        currentRow.raci = obj.value !== null ? obj.value : ""
      }
      if (param.curQuestion.append_to.toUpperCase() === "ACTIVITY") {
        if (currentRow.itemId > 0) {
          if (+userContext.tempUid !== 3) {
            if ((currentRow.itemValue !== currentRow.itemOldValue || currentRow.raci !== currentRow.raciOld) && (currentRow.itemValue !== "" && currentRow.raci !== "")) {
              currentRow.isBtnVisible = true;
              setDisabled(true);
            }
            else {
              currentRow.isBtnVisible = false;
              setDisabled(false);
            }
          } else {
            if ((currentRow.itemValue !== currentRow.itemOldValue) && (currentRow.itemValue !== "" && (currentRow.raci === "" || currentRow.raci === undefined))) {
              currentRow.isBtnVisible = true;
              setDisabled(true);
            }
            else {
              currentRow.isBtnVisible = false;
              setDisabled(false);
            }
          }
        } else {
          if ((currentRow.itemValue !== currentRow.itemOldValue && currentRow.raci !== currentRow.raciOld) && (currentRow.itemValue !== "" && currentRow.raci !== "")) {
            currentRow.isBtnVisible = true;
            setDisabled(true);
          }
          else {
            currentRow.isBtnVisible = false;
            setDisabled(false);
          }
        }
      } else {
        if (currentRow.itemValue !== currentRow.itemOldValue) {
          currentRow.isBtnVisible = true;
          setDisabled(true);
        } else {
          currentRow.isBtnVisible = false;
          setDisabled(false);
        }
      }
    }
    detailColl[rowNumber] = currentRow;
    setItemData({ ...itemData, itemDetailCollection: detailColl });
  };

  const handleItemFocus = (rowNumber: number) => {
    let detailColl = itemData.itemDetailCollection;
    let currentRow = detailColl[rowNumber];
    detailColl.forEach(element => {
      element.isFocused = false;
    });
    if (currentRow) {
      currentRow.isFocused = !currentRow.isFocused;
    }
    detailColl[rowNumber] = currentRow;
    setItemData({ ...itemData, itemDetailCollection: detailColl });
  }

  const handleItemUnfocus = () => {
    let detailColl = itemData.itemDetailCollection;
    detailColl.forEach(element => {
      element.isFocused = false;
    });
    setItemData({ ...itemData, itemDetailCollection: detailColl });
  }

  // async function submit(values: any, resetForm: any) {
  //   let itemsData = values.itemDetailCollection.filter(
  //     (x: IItemDetail) => (x.itemValue !== "" && x.isDeleted === false)
  //   );
  //   let isValidate = true;
  //   if (param.curQuestion.append_to.toUpperCase() === "ACTIVITY" || param.curQuestion.append_to.toUpperCase() === "ROLE") {
  //     isValidate = false;
  //   }

  //   if (param.curQuestion.append_to.toUpperCase() === "PROJ") {
  //     let params = new URLSearchParams();
  //     params.append("huid", userContext.hiagramId.toString());
  //     params.append("quid", "1");
  //     let data: any;
  //     data = await GetTeamList(params);
  //     if (data !== null && data.data !== null && data.data.data !== null) {
  //       let teamList: any = data.data.data;
  //       if (teamList.length === 0) {
  //         isValidate = false;
  //       }
  //     }
  //   }

  //   if (itemsData.length > 0 || isValidate === true) {
  //     param.startLoading();
  //     try {
  //       let items = values.itemDetailCollection.filter(
  //         (x: IItemDetail) => (x.itemValue !== "" || x.itemId > 0)
  //       );
  //       let projUID = 0;
  //       let teamUID = 0;
  //       let roleUID = userContext.roleId;
  //       let activityUID = userContext.activityId;
  //       if (userContext.isProj.toString() === "false") {
  //         teamUID = userContext.teamProjId;
  //       } else if (userContext.isProj.toString() === "true") {
  //         projUID = userContext.teamProjId;
  //       }
  //       let requestData: any[] = [];
  //       items.forEach((element: any) => {
  //         let item = {
  //           hiagramUid: userContext.hiagramId,
  //           questionUid: param.curQuestion.uid,
  //           questionAnswerUid: element.iteamAnswerId,
  //           itemId: element.itemId,
  //           itemValue: element.itemValue,
  //           raci: (element.raci === null || element.raci === undefined) ? "" : element.raci,
  //           teamUid: teamUID,
  //           projUid: projUID,
  //           roleUid: roleUID,
  //           activityUid: activityUID,
  //           isDeleted: element.isDeleted,
  //           dataManagedBy: userContext.userId
  //         }
  //         requestData.push(item);
  //       });

  //       if (itemsData.length === 0) {
  //         let item = {
  //           hiagramUid: userContext.hiagramId,
  //           questionUid: param.curQuestion.uid,
  //           questionAnswerUid: 0,
  //           itemId: -1,
  //           itemValue: "None",
  //           raci: "None",
  //           teamUid: 0,
  //           projUid: 0,
  //           roleUid: 0,
  //           activityUid: 0,
  //           isDeleted: false,
  //           dataManagedBy: userContext.userId
  //         }
  //         requestData.push(item);
  //       }

  //       let saveData: any;

  //       if (param.curQuestion.append_to.toUpperCase() === "TEAM") {
  //         saveData = await CreateTeam(requestData);
  //       } else if (param.curQuestion.append_to.toUpperCase() === "PROJ") {
  //         saveData = await CreateProject(requestData);
  //       } else if (param.curQuestion.append_to.toUpperCase() === "ROLE") {
  //         saveData = await CreateRole(requestData);
  //       } else if (param.curQuestion.append_to.toUpperCase() === "ACTIVITY") {
  //         saveData = await CreateActivity(requestData);
  //       } else if (param.curQuestion.append_to.toUpperCase() === "CESS") {
  //         saveData = await CreateCess(requestData);
  //       }
  //       if (saveData.data === true) {
  //         param.changeNextQuestion();
  //       }
  //     }
  //     catch (error: any) {
  //       if (error) {
  //         updateUserContext({
  //           ...userContext,
  //           isAlert: true,
  //           alertMessage: error.message,
  //           alertType: AlertType.Error,
  //         });
  //       }
  //     }
  //   } else {
  //     let name = param.curQuestion.append_to;
  //     if (param.curQuestion.append_to.toUpperCase() === "PROJ") {
  //       name = "TEAM or PROJECT";
  //     }
  //     updateUserContext({
  //       ...userContext,
  //       isAlert: true,
  //       alertMessage: "Please add at least one " + name,
  //       alertType: AlertType.Error,
  //     });
  //   }
  // }

  async function submit(values: any, resetForm: any) {
    debugger
    let itemsData = values?.itemDetailCollection?.filter(
      (x: IItemDetail) => (x.itemValue !== "" && x.isDeleted === false && x.itemId > 0)
    ) || [];
    localStorage.setItem('previousQuestion', JSON.stringify(itemsData || ''))
    if (userContext.isEditAccess === true) {
      let isValidate = true;
      if (param.curQuestion.append_to.toUpperCase() === "ACTIVITY" || param.curQuestion.append_to.toUpperCase() === "ROLE") {
        isValidate = false;
      }

      if (param.curQuestion.append_to.toUpperCase() === "PROJ") {
        let params = new URLSearchParams();
        params.append("huid", userContext.hiagramId.toString());
        params.append("quid", "1");
        let data: any;
        data = await GetTeamList(params);
        if (data !== null && data.data !== null && data.data.data !== null) {
          let teamList: any = data.data.data;
          if (teamList.data.length === 0) {
            isValidate = false;
          }
        }
      }

      if (itemsData.length > 0 || isValidate === true) {
        param.startLoading();
        try {
          let items = values.itemDetailCollection.filter(
            (x: IItemDetail) => (x.itemValue !== "" || x.itemId > 0)
          );
          let projUID = 0;
          let teamUID = 0;
          let roleUID = userContext.roleId;
          let activityUID = userContext.activityId;
          if (userContext.isProj.toString() === "false") {
            teamUID = userContext.teamProjId;
          } else if (userContext.isProj.toString() === "true") {
            projUID = userContext.teamProjId;
          }
          let requestData: any[] = [];
          if (itemsData.length === 0) {
            let item = {
              tempUid: userContext.tempUid,
              hiagramUid: userContext.hiagramId,
              questionUid: param.curQuestion.uid,
              questionAnswerUid: 0,
              itemId: -1,
              itemValue: "None",
              raci: "None",
              teamUid: teamUID,
              projUid: projUID,
              roleUid: roleUID,
              activityUid: activityUID,
              isDeleted: false,
              dataManagedBy: userContext.userId
            }
            requestData.push(item);
            let saveData: any;
            if (param.curQuestion.append_to.toUpperCase() === "TEAM") {
              saveData = await CreateTeam(requestData);
            } else if (param.curQuestion.append_to.toUpperCase() === "PROJ") {
              saveData = await CreateProject(requestData);
            } else if (param.curQuestion.append_to.toUpperCase() === "ROLE") {
              saveData = await CreateRole(requestData);
            } else if (param.curQuestion.append_to.toUpperCase() === "ACTIVITY") {
              saveData = await CreateActivity(requestData);
            } else if (param.curQuestion.append_to.toUpperCase() === "CESS") {
              saveData = await CreateCess(requestData);
            }
            // if (saveData.data === true) {
            //   param.changeNextQuestion();
            // }

            // hp code start
            if (saveData.data !== undefined && saveData.data.data !== undefined && saveData.data.data.dataTrue === true) {
              param.changeNextQuestion();
            } else if (saveData.data !== undefined && saveData.data === true) {
              param.changeNextQuestion();
            }
            else if (saveData.data !== undefined && saveData.data.dataTrue === true) {
              param.changeNextQuestion();
            }
            // hp code end
          }
          else {
            param.changeNextQuestion();
          }
        }
        catch (error: any) {
          if (error) {
            updateUserContext({
              ...userContext,
              isAlert: true,
              alertMessage: error.message,
              alertType: AlertType.Error,
            });
          }
        }
      } else {
        let name = param.curQuestion.append_to;
        if (param.curQuestion.append_to.toUpperCase() === "PROJ") {
          name = "TEAM or PROJECT";
        } else if (param.curQuestion.append_to.toUpperCase() === "ACTIVITY") {
          name = "ROLE";
        }
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: "Please add at least one " + name,
          alertType: AlertType.Error,
        });
      }
    } else {
      param.changeNextQuestion();
    }
  }

  async function getdefaultDescription(isInit: boolean) {
    //setGptAnswer("I'm thinking. . . . .");
    var plainString = "";
    if (+param.curQuestion.uid === 21) {
      // plainString = `Give me the top 5 example activities that might  be undertaken by a role named "${userContext.roleName}" within a team named "${userContext.teamProjName}" that has the purpose "${userContext.teamProjPurpose}".`;
      plainString = `Give me the top 5 example activities that might be undertaken by a role named "${userContext.roleName}" within a team named "${userContext.teamProjName}" that has the purpose "${userContext.teamProjPurpose}". Each should be a maximum of 90 characters.`
    }

    if (plainString !== "") {
      setLoading(true);
      setAIBoxVisible(true);
      let requestData = {
        text: plainString
      }
      if (CUR_ENV !== "LOCAL") {
        let data: any = await RephraseText(requestData);
        if (data !== null && data.choices !== null && data.choices.length > 0) {
          let answers = data.choices;
          let firstAnswer = answers[0].text.replace(/^\s+|\s+$/g, '');
          //let firstAnswer = answers[0].text;
          if (isInit) {
            setGptAnswers([firstAnswer]);
          } else {
            setGptAnswers([...gptAnswers, firstAnswer]);
          }

        } else {
          // setGptAnswers(["Hello . . ."]);
        }
      }
      else {
        console.log(requestData);
      }
      setLoading(false);
    } else {
      setAIBoxVisible(false);
    }
  }

  async function fetchData() {
    // setShowLoder(isPageloading);
    try {
      let params = new URLSearchParams();
      params.append("huid", userContext.hiagramId.toString());
      params.append("quid", param.curQuestion.uid.toString());
      let data: any;
      if (param.curQuestion.append_to.toUpperCase() === "TEAM") {
        data = await GetTeamList(params);
        setOthersDataCount(data.data.data.total_project_count)
        updateUserContext({
          ...userContext,
          teamProjId: 0,
          teamProjName: "",
          teamProjPurpose: "",
          isProj: "false",
          roleId: 0,
          roleName: "",
          activityId: 0,
          activityName: "",
          cessId: 0,
          cessName: "",
          stepId: 0,
          stepName: ""
        });
      } else if (param.curQuestion.append_to.toUpperCase() === "PROJ") {
        data = await GetProjectList(params);
        setOthersDataCount(data.data.data.total_team_count)
        updateUserContext({
          ...userContext,
          teamProjId: 0,
          teamProjName: "",
          teamProjPurpose: "",
          isProj: "false",
          roleId: 0,
          roleName: "",
          activityId: 0,
          activityName: "",
          cessId: 0,
          cessName: "",
          stepId: 0,
          stepName: ""
        });
      } else if (param.curQuestion.append_to.toUpperCase() === "ROLE") {
        let projUID = 0;
        let teamUID = 0;
        if (userContext.isProj.toString() === "false") {
          teamUID = userContext.teamProjId;
        } else if (userContext.isProj.toString() === "true") {
          projUID = userContext.teamProjId;
        }
        params.append("tuid", teamUID.toString());
        params.append("puid", projUID.toString());
        data = await GetRoleList(params);
        updateUserContext({
          ...userContext,
          roleId: 0,
          roleName: "",
          activityId: 0,
          activityName: "",
          cessId: 0,
          cessName: "",
          stepId: 0,
          stepName: ""
        });
      } else if (param.curQuestion.append_to.toUpperCase() === "ACTIVITY") {
        params.append("ruid", userContext.roleId.toString());
        data = await GetActivityList(params);
        updateUserContext({
          ...userContext,
          activityId: 0,
          activityName: "",
          cessId: 0,
          cessName: "",
          stepId: 0,
          stepName: ""
        });
      } else if (param.curQuestion.append_to.toUpperCase() === "CESS") {
        params.append("auid", userContext.activityId.toString());
        data = await GetCessList(params);
        updateUserContext({
          ...userContext,
          cessId: 0,
          cessName: "",
          stepId: 0,
          stepName: ""
        });
      }

      if (data.data != null && data.data.data != null) {
        let allItems: any;
        if (param.curQuestion.append_to.toUpperCase() === "TEAM" || param.curQuestion.append_to.toUpperCase() === "PROJ") {
          allItems = data.data.data.data;
        } else {
          allItems = data.data.data;
        }

        if (allItems.length > 0) {
          let detailColl: [IItemDetail] = [initItemDetailData];
          let rowCounter = 0;
          detailColl.pop();
          allItems.forEach((element: { uid: string | number; questionAnswer: { uid: string | number; }[]; name: any; raci: any; }) => {
            let item: IItemDetail = {
              rowNo: rowCounter++,
              itemId: +element.uid,
              iteamAnswerId: +element.questionAnswer[0].uid,
              itemOldValue: element.name,
              itemValue: element.name,
              raciOld: (element.raci === null || element.raci === undefined) ? "" : element.raci,
              raci: (element.raci === null || element.raci === undefined) ? "" : element.raci,
              isFocused: false,
              isLoading: false,
              isDeleted: false,
              isBtnVisible: false,
              isDeleting: false
            }
            detailColl.push(item);
          });
          // if (allItems.length > 1) {
          //   let item: IItemDetail = {
          //     rowNo: rowCounter++,
          //     itemId: 0,
          //     iteamAnswerId: 0,
          //     itemOldValue: "",
          //     itemValue: "",
          //     raci: "",
          //     isFocused: false,
          //     isLoading: false,
          //     isDeleted: false,
          //   }
          //   detailColl.push(item);
          // }
          setItemData({ ...itemData, itemDetailCollection: detailColl });
        } else {
          let detailColl = itemData.itemDetailCollection;
          detailColl = [{
            rowNo: 0,
            itemId: 0,
            iteamAnswerId: 0,
            itemOldValue: "",
            itemValue: "",
            raciOld: "",
            raci: "",
            isFocused: false,
            isLoading: false,
            isDeleted: false,
            isBtnVisible: false,
            isDeleting: false
          }];
          setItemData({ ...itemData, itemDetailCollection: detailColl });
        }
      }
      else {
        let detailColl = itemData.itemDetailCollection;
        detailColl = [{
          rowNo: 0,
          itemId: 0,
          iteamAnswerId: 0,
          itemOldValue: "",
          itemValue: "",
          raciOld: "",
          raci: "",
          isFocused: false,
          isLoading: false,
          isDeleted: false,
          isBtnVisible: false,
          isDeleting: false
        }];
        setItemData({ ...itemData, itemDetailCollection: detailColl });
      }
      setDisabled(false);
    }
    catch (error: any) {
      if (error) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: error.response.message,
          alertType: AlertType.Error,
        });
      }
    }
    // setShowLoder(false);
  }

  async function fetchRACI() {
    try {
      const raciGetData: any = await GetRaciList();
      if (raciGetData !== undefined && raciGetData !== null && raciGetData.data !== null && raciGetData.data.data !== null && raciGetData.data.data.data !== null) {
        let RaciData = raciGetData.data.data.data
        const filterRaciOptions = RaciData.filter((raciO: any) => raciO.value === 'RESPONSIBLE' || raciO.value === 'ACCOUNTABLE');
        setRaciData(filterRaciOptions);
      }
    }
    catch (error: any) {
      if (error) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: error.response.message,
          alertType: AlertType.Error,
        });
      }
    }
  }

  async function AddUpdateData(row: number, isDeleted: Boolean) {
    debugger
    let detailColl = itemData.itemDetailCollection;
    let currentRow = detailColl[row];
    if (isDeleted) {
      detailColl[row].isDeleting = true;
    } else {
      detailColl[row].isLoading = true;
    }
    setItemData({ ...itemData, itemDetailCollection: detailColl });
    try {
      let projUID = 0;
      let teamUID = 0;
      let roleUID = userContext.roleId;
      let roleName = userContext.roleName;
      let activityUID = userContext.activityId;
      if (userContext.isProj.toString() === "false") {
        teamUID = userContext.teamProjId;
      } else if (userContext.isProj.toString() === "true") {
        projUID = userContext.teamProjId;
      }
      let requestData: any[] = [];
      let item = {
        tempUid: userContext.tempUid,
        hiagramUid: userContext.hiagramId,
        questionUid: param.curQuestion.uid,
        questionAnswerUid: currentRow.iteamAnswerId,
        itemId: currentRow.itemId,
        itemValue: currentRow.itemValue,
        raci: (currentRow.raci === null || currentRow.raci === undefined) ? "" : currentRow.raci,
        teamUid: teamUID,
        projUid: userContext.tempUid === 2 ? userContext.teamProjId : projUID,
        roleUid: userContext.tempUid === 2 ? userContext.roleId : roleUID,
        // Added Role Name NP
        roleName: userContext.tempUid === 2 ? userContext.roleName : roleName,
        activityUid: activityUID,
        isDeleted: isDeleted,
        dataManagedBy: userContext.userId
      }
      requestData.push(item);
      let saveData: any;
      if (param.curQuestion.append_to.toUpperCase() === "TEAM") {
        saveData = await CreateTeam(requestData);
      } else if (param.curQuestion.append_to.toUpperCase() === "PROJ") {
        saveData = await CreateProject(requestData);
      } else if (param.curQuestion.append_to.toUpperCase() === "ROLE") {
        saveData = await CreateRole(requestData);
      } else if (param.curQuestion.append_to.toUpperCase() === "ACTIVITY") {
        saveData = await CreateActivity(requestData);
      } else if (param.curQuestion.append_to.toUpperCase() === "CESS") {
        saveData = await CreateCess(requestData);
      }
      // if (saveData.data === true) {
      //   fetchData();
      //   //param.changeNextQuestion();
      // }

      // hp code start
      if (saveData.data !== undefined && saveData.data.data !== undefined && saveData.data.data.dataTrue === true) {
        fetchData();
      } else if (saveData.data !== undefined && saveData.data === true) {
        fetchData();
      }
      else if (saveData.data !== undefined && saveData.data.dataTrue === true) {
        fetchData();
      }

      detailColl[row].isLoading = false;
      // setIsSaveBtnVisiable(false);
      // hp code end
    }
    catch (error: any) {
      if (error) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: error.message,
          alertType: AlertType.Error,
        });
      }
    }

  }
  function getPreviousDescription() {
    let index = curIndex;
    if (index > 0) {
      setCurIndex(index - 1);
    }
  }


  useEffect(() => {
  }, [itemData, curIndex]);

  useEffect(() => {
    setCurIndex(gptAnswers.length - 1);
  }, [gptAnswers]);


  return (
    <>
      <Formik
        enableReinitialize
        initialValues={itemData}
        onSubmit={(value: any, { resetForm }) => {
          submit(value, resetForm);
        }}
      >
        {(props: any) => {
          return (
            <Form onSubmit={props.handleSubmit} noValidate>
              <Grid container className="main-grid-many">
                <Grid item xs={6}>
                  <Grid container className="main-itemlist">
                    {itemData &&
                      itemData.itemDetailCollection &&
                      itemData.itemDetailCollection
                        .filter((x) => x.isDeleted === false)
                        .map((dataRow: any, i: any) => {
                          return (
                            // <Box className="inner-item-list">

                            <Grid item xs={12} className="item-list" key={dataRow.rowNo}>
                              <Typography className="number-line">{i + 1}.</Typography>
                              <Box className="control-box">
                                <Box className="textinput-lable">
                                  <TextField
                                    sx={{
                                      '& .MuiInputBase-input': {
                                        fontFamily: 'Roboto'
                                      }
                                    }}
                                    fullWidth
                                    disabled={!userContext.isEditAccess}
                                    placeholder={"Answer " + (i + 1)}
                                    inputProps={{ maxLength: param.curQuestion.max_length !== null ? param.curQuestion.max_length : 100 }}
                                    key={dataRow.rowNo}
                                    onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                                    onChange={(e) => {
                                      props.setFieldValue(
                                        `itemDetailCollection[${dataRow.rowNo}].itemValue`,
                                        e.target.value !== null
                                          ? e.target.value
                                          : "",
                                        false
                                      )
                                      handleItemDetailChange(props, dataRow.rowNo, e, 1);

                                      if (e.target.value !== null && e.target.value !== undefined) {
                                        if (e.target.value !== itemData.itemDetailCollection[dataRow.rowNo].itemOldValue) {
                                          // setIsSaveBtnVisiable(true);
                                        } else {
                                          // setIsSaveBtnVisiable(false);
                                        }
                                      }

                                    }}
                                    onFocus={() => {
                                      handleItemFocus(dataRow.rowNo)
                                    }}
                                    onBlur={handleItemUnfocus}
                                    value={props.values.itemDetailCollection &&
                                      props.values.itemDetailCollection[dataRow.rowNo] &&
                                      props.values.itemDetailCollection[dataRow.rowNo].itemValue !== null
                                      ? props.values.itemDetailCollection[dataRow.rowNo].itemValue
                                      : ""}
                                  ></TextField>
                                  {itemData.itemDetailCollection[dataRow.rowNo].isFocused
                                    && <Typography className="length-counter">{props.values.itemDetailCollection[dataRow.rowNo].itemValue.length + "/" + (param.curQuestion.max_length !== null ? param.curQuestion.max_length : 100)}</Typography>}
                                </Box>
                                {param.curQuestion.append_to.toUpperCase() === "ACTIVITY" ?
                                  <>
                                    {+userContext.tempUid !== 3 ?
                                      <Select
                                        className="dropdown-list"
                                        classNamePrefix="select"
                                        key={dataRow.rowNo}
                                        isDisabled={isRaciDisabled || !userContext.isEditAccess}
                                        // isLoading={isLoading}
                                        isClearable={true}
                                        isSearchable={true}
                                        // placeholder={'Contrary to popular belief'}
                                        placeholder={''}
                                        components={{
                                          IndicatorSeparator: () => null
                                        }}
                                        value={getSingleSelected(
                                          // props.values.raci,
                                          (props.values.itemDetailCollection &&
                                            props.values.itemDetailCollection[dataRow.rowNo] &&
                                            props.values.itemDetailCollection[dataRow.rowNo].raci !== null
                                            ? props.values.itemDetailCollection[dataRow.rowNo].raci
                                            : ""),
                                          raciData
                                        )}
                                        onChange={(e, value) => {
                                          props.setFieldValue(
                                            // "raci",
                                            `itemDetailCollection[${dataRow.rowNo}].raci`,
                                            e !== null ? e.value : "",
                                            false
                                          );
                                          handleItemDetailChange(props, dataRow.rowNo, e, 2)
                                        }}
                                        options={raciData}
                                        styles={selectStyles}
                                        // styles={{
                                        //   control: (baseStyles, state) => ({
                                        //     ...baseStyles,
                                        //     background: '#FFFFFF'
                                        //   }),
                                        // }}
                                        theme={(theme) => ({
                                          ...theme,
                                          colors: {
                                            ...theme.colors,
                                            primary: mainTheme.palette.primary.main,
                                            primary25: '#FEF4F4',
                                            primary50: '#FEF4F4',
                                          },
                                        })
                                        }
                                      /> : <></>}
                                  </> : <></>}
                              </Box>

                              {props.values.itemDetailCollection &&
                                props.values.itemDetailCollection[dataRow.rowNo] &&
                                itemData.itemDetailCollection[dataRow.rowNo].isBtnVisible === true ?
                                <Button
                                  className="button-save"
                                  // disabled={props.values.itemDetailCollection[dataRow.rowNo].isLoading !== undefined && props.values.itemDetailCollection[dataRow.rowNo].isLoading || !userContext.isEditAccess}
                                  onClick={() => AddUpdateData(dataRow.rowNo, false)}>
                                  {props.values.itemDetailCollection[dataRow.rowNo].isLoading === true ? <CircularProgress size={24} sx={{ color: "white" }} /> : <img loading="lazy" src={tickicon} alt="logo" />}
                                </Button> : <></>}
                              {props.values.itemDetailCollection &&
                                props.values.itemDetailCollection[dataRow.rowNo] &&
                                props.values.itemDetailCollection[dataRow.rowNo].itemId > 0 ?
                                <Button
                                  className="button-delete"
                                  style={{ marginRight: 10 }}
                                  disabled={(props.values.itemDetailCollection[dataRow.rowNo].isDeleting || props.values.itemDetailCollection[dataRow.rowNo].isLoading) || !userContext.isEditAccess}
                                  // onClick={() =>
                                  //   AddUpdateData(dataRow.rowNo, true)
                                  // }
                                  onClick={() =>
                                    handleOpenDeleteModal(dataRow.rowNo)
                                  }
                                >
                                  {props.values.itemDetailCollection[dataRow.rowNo].isDeleting === true ? <CircularProgress size={24} sx={{ color: "white" }} /> : <img src={deleteicon} loading="lazy" alt="logo" />}
                                </Button> : <></>}

                            </Grid>

                          )
                        })}
                    <Grid item className="item-add-button">
                      <Button
                        className="button button-line-add"
                        onClick={handleAddNewRow}
                        variant="outlined"
                        disabled={isDisabled || !userContext.isEditAccess}
                        //startIcon={<img loading="lazy" src={addicon} alt="logo" />}
                        startIcon={<AddIcon />}
                      >
                        <Typography className="btn-add">Add</Typography>
                      </Button>
                    </Grid>
                    <Button className="inner-button" variant="contained" type="submit"></Button>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  {isAIBoxVisible ?
                    <Box className="gpt-description-box">
                      <Box className="gpt-description-upper">
                        <Typography className="textfield-label">
                          imprend AI: this is NOT saved
                        </Typography>
                        <Box>
                          <Tooltip title="Undo">
                            <Button
                              className="btn-undo"
                              variant="outlined"
                              disableRipple={true}
                              onClick={getPreviousDescription}
                            >
                              <ReplayIcon sx={{ color: '#a7679b' }} />
                            </Button>
                          </Tooltip>
                          <Tooltip title="Another example">
                            <Button
                              className="btn-new-ex"
                              variant="outlined"
                              disableRipple={true}
                              onClick={() => getdefaultDescription(false)}
                            >
                              <OfflineBoltOutlinedIcon sx={{ color: '#a7679b' }} />
                            </Button>
                          </Tooltip>
                        </Box>
                      </Box>

                      <Typography className="textfield-gpt-label">
                        {isLoading ? "I'm thinking . . ." : gptAnswers[curIndex]}
                      </Typography>
                    </Box> : <></>}
                </Grid>
              </Grid>
            </Form>
          );
        }}

      </Formik>
      <Dialog
        open={deleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='delete-many-dialog-box'
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className='popup-text'>
            {param.curQuestion.append_to.toLowerCase() === 'proj' ?
              //userContext.tempUid === 3 ? "WARNING! Deleting this Project will also delete any Roles and Processes beneath it." : "WARNING! Deleting this Project will also delete any Roles, Jobs and Processes beneath it." :
              userContext.tempUid === 3 ? "WARNING! Deleting this Project will also delete any Roles and Processes beneath it." : "WARNING! Deleting this Project will also delete any Roles and Processes beneath it." :
              param.curQuestion.append_to.toLowerCase() === 'team' ?
                //userContext.tempUid === 3 ? "WARNING! Deleting this Team will also delete any Roles and Processes beneath it." : "WARNING! Deleting this Team will also delete any Roles, Jobs and Processes beneath it." :
                userContext.tempUid === 3 ? "WARNING! Deleting this Team will also delete any Roles and Processes beneath it." : "WARNING! Deleting this Team will also delete any Roles and Processes beneath it." :
                param.curQuestion.append_to.toLowerCase() === 'role' ?
                  "WARNING! Deleting this Role will also delete any Activities and Processes beneath it." :
                  param.curQuestion.append_to.toLowerCase() === 'activity' ?
                    "WARNING! Deleting this Role will also delete any Processes beneath it." :
                    "WARNING! Deleting this Process will also delete any Steps beneath it."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant='outlined'
            // onClick={() => deleteStake()}
            onClick={() => {
              AddUpdateData(curRow, true);
              setDeleteModal(false);
            }}
            className="popup-deleted-btn"
          // loading={isBtnLoading}
          >
            Delete
          </LoadingButton>
          <Button
            variant='contained'
            onClick={handleCloseDeleteModal}
            className="popup-btn"
          >
            Keep
          </Button>
        </DialogActions>
      </Dialog>

      {/* popup for ADDNEW ROW */}

      <Dialog
        maxWidth="xs"
        fullWidth={true}
        open={contWarningModel}
        // TransitionComponent={Transition}
        //onClose={handleCloseWelcomeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='count-dialog-box'
      >
        <Box className='count-img-box'>
          {/* <img loading="lazy" alt="imprend" className='welecome-icon' src={userImage} /> */}
          <HelpIcon className="count-icon" />
        </Box>
        <DialogTitle className='count-dialog-title'>Another one?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className='count-popup-text'>
            {warningShowMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="count-dialog-action">
          <Button
            variant='contained'
            onClick={handleCloseWelcomeModal}
            className="count-close-btn">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

const selectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: '#FFFFFF'
  })
};

export default StandardMany;
import React, { useContext, useEffect, useRef, useState } from 'react';
import './ProjectDetail.scss';
import { Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, Grid, Paper, Skeleton, Slide, TextField, Typography, useTheme } from '@mui/material';
import Project from '../../../assets/icons/card-project-icon.svg';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import NodeComponent from '../../AnswerType/NodeComponent/NodeComponent';
import TimeIcon from '../../../assets/icons/time-icon.svg'
import Document from '../../../assets/icons/doc.svg'
import Hint from '../../../assets/icons/hint.svg'
import People from '../../../assets/icons/person-icon.svg'
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import Select from "react-select";
import editIcon from '../../../assets/icons/edit-2.svg'
import tickicon from "../../../assets/icons/tick.svg";
import { TransitionProps } from '@mui/material/transitions';
import ItemDocAdd from '../../AnswerType/ItemDocAdd/ItemDocAdd';
import ItemHintAdd from '../../AnswerType/ItemHintAdd/ItemHintAdd';
import ItemStakeAdd from '../../AnswerType/ItemStakeAdd/ItemStakeAdd';
import ItemActionAdd from '../../AnswerType/ItemActionAdd/ItemActionAdd';
import { PreferencesContext } from '../../../PreferenceContext';
import { GetStatusList, ProjectGetAllDetail, UpdateProjDataOfProjectCard } from '../../../services/ProjectService';
import { AlertType } from '../../../shared/AlertType';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { getSingleSelected } from '../../../services/common/commonFunction';

const formInitialValues = {
    id: 0,
    name: "",
    description: "",
    status: "",
    statusReason: "",
    purpose: "",
};

const formValidation = Yup.object().shape({
    name: Yup.string().required("Name is required."),
    description: Yup.string().required("Description is required."),

});

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

let initdata: any;

export default function ProjectDetail(params: any) {
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [isAuthorize, setAuthorize] = useState(userContext.isEditAccess);
    const [projectDetailData, setProjectDetailData] = useState(formInitialValues);

    const [isFieldNameEdit, setFieldNameEdit] = useState(false);
    const [isFieldNameSave, setFieldNameSave] = useState(false);

    const [isFieldPurposeEdit, setFieldPurposeEdit] = useState(false);
    const [isFieldPurposeSave, setFieldPurposeSave] = useState(false);

    const [isDataUpdated, setDataUpdated] = useState(false);

    const [isFieldDescriptionEdit, setFieldDescriptionEdit] = useState(false);
    const [isFieldDescriptionSave, setFieldDescriptionSave] = useState(false);

    const [isFieldStatusEdit, setFieldStatusEdit] = useState(false);
    const [isFieldStatusSave, setFieldStatusSave] = useState(false);

    const [isFieldStatusReasonEdit, setFieldStatusReasonEdit] = useState(false);
    const [isFieldStatusReasonSave, setFieldStatusReasonSave] = useState(false);

    const [isButtonLoading, setButtonLoading] = useState(false);
    const [isDeleteButtonLoading, setDeleteButtonLoading] = useState(false);

    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const [isFocusedName, setFocusedName] = useState(false);
    const [isFocusedPurpose, setFocusedPurpose] = useState(false);
    const [isFocusedDescription, setFocusedDescription] = useState(false);
    const [isFocusedStatus, setFocusedStatus] = useState(false);
    const [isFocusedStatusReason, setFocusedStatusReason] = useState(false);

    const [isFieldEditCommon, setFieldEditCommon] = useState(false);
    const [openDialogBoxPeople, setOpenDialogBoxPeople] = useState(false);
    const [openDialogBoxHint, setopenDialogBoxHint] = useState(false);
    const [openDialogBoxAction, setopenDialogBoxAction] = useState(false);
    const [openDialogBoxDocument, setopenDialogBoxDocument] = useState(false);
    const [projectData, setProjectData] = useState(initdata);
    const [isLoading, setLoading] = useState(false);

    const childIteamAddActionCompRef: any = useRef();
    const childIteamAddStakeCompRef: any = useRef();
    const childIteamAddDocCompRef: any = useRef();
    const childIteamAddHintCompRef: any = useRef();
    const mainTheme = useTheme();

    let navigate = useNavigate();

    const handleClickPeopleNode = () => {
        setOpenDialogBoxPeople(true);
    };
    const handleClickDocumentNode = () => {
        setopenDialogBoxDocument(true);
    };

    const handleClickHintNode = () => {
        setopenDialogBoxHint(true);
    };

    const handleClickActionNode = () => {
        setopenDialogBoxAction(true);
    };

    const handleClosePeopleNode = (event: any, reason: any) => {
        if (reason && reason === "backdropClick")
            return;
        setOpenDialogBoxPeople(false);
        fetchData();
    };
    const handleCloseHintNode = (event: any, reason: any) => {
        if (reason && reason === "backdropClick")
            return;
        setopenDialogBoxHint(false);
        fetchData();
    };
    const handleCloseDocumentNode = (event: any, reason: any) => {
        if (reason && reason === "backdropClick")
            return;
        setopenDialogBoxDocument(false);
        fetchData();
    };
    const handleCloseActionNode = (event: any, reason: any) => {
        if (reason && reason === "backdropClick")
            return;
        setopenDialogBoxAction(false);
        fetchData();
    };

    const handleCloseDeleteModal = (isCheck: any) => {
        if (isCheck) {
            updateUserContext({
                ...userContext,
                isCardCountUpdate: true
            })
            params.onClose(isDataUpdated);
        }
        setOpenDeleteModal(false);
    };

    const handleCloseModal = () => {
        if (isFieldDescriptionSave || isFieldNameSave || isFieldStatusSave || isFieldStatusReasonSave) {
            setOpenDeleteModal(true);
        }
        else {
            updateUserContext({
                ...userContext,
                isCardCountUpdate: true
            })
            params.onClose(isDataUpdated);
        }

    };

    const curQuestionAction = {
        number: 4,
        uid: 7,
        hiagram: {
            type: "PROJ",
            typeId: params.dataId.split('-')[1]
        }
    }
    const curQuestionHint = {
        number: 7,
        uid: 12,
        hiagram: {
            type: "PROJ",
            typeId: params.dataId.split('-')[1]
        }
    }
    const curQuestionDoc = {
        number: 6,
        uid: 10,
        hiagram: {
            type: "PROJ",
            typeId: params.dataId.split('-')[1]
        }
    }

    const curQuestionStack = {
        number: 5,
        uid: 9,
        hiagram: {
            type: "PROJ",
            typeId: params.dataId.split('-')[1]
        }
    }

    async function editName() {
        setFieldNameEdit(true);
    }


    async function cancelName(props: any) {
        props.setFieldValue(
            `name`,
            projectDetailData.name,
            true
        );
        setFieldNameEdit(false);
        setFieldNameSave(false);
    }

    async function editPurpose() {
        setFieldPurposeEdit(true);
    }


    async function cancelPurpose(props: any) {
        props.setFieldValue(
            `purpose`,
            projectDetailData.purpose,
            true
        );
        setFieldPurposeEdit(false);
        setFieldPurposeSave(false);
    }

    async function editDescription() {
        setFieldDescriptionEdit(true);
    }
    async function editStatus() {
        setFieldStatusEdit(true);
    }
    async function editStatusReason() {
        setFieldStatusReasonEdit(true);
    }

    async function cancelDescription(props: any) {
        props.setFieldValue(
            `description`,
            projectDetailData.description,
            true
        );
        setFieldDescriptionEdit(false);
        setFieldDescriptionSave(false);
    }
    async function cancelStatus(props: any) {
        props.setFieldValue(
            `status`,
            projectDetailData.status,
            true
        );
        setFieldStatusEdit(false);
        setFieldStatusSave(false);
    }
    async function cancelStatusReason(props: any) {
        props.setFieldValue(
            `statusReason`,
            projectDetailData.statusReason,
            true
        );
        setFieldStatusReasonEdit(false);
        setFieldStatusReasonSave(false);
    }

    async function addRow(values: any) {
        setButtonLoading(true);
        try {
            let requestData = {
                userUid: params.dataId.split('-')[1].toString(),
                hiagramUid: userContext.hiagramId,
                dataManagedBy: userContext.userId,
                name: values.name,
                purpose: values.purpose,
                description: values.description,
                status: values.status,
                statusReason: values.statusReason
            }
            let data: any;
            data = await UpdateProjDataOfProjectCard(requestData);

            if (data) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: "Project updated!",
                    alertType: AlertType.Success,
                });
                setFieldNameEdit(false);
                setFieldNameSave(false);
                setFieldPurposeEdit(false);
                setFieldPurposeSave(false);
                setFieldDescriptionEdit(false);
                setFieldStatusEdit(false);
                setFieldStatusReasonEdit(false);
                setFieldDescriptionSave(false);
                setFieldStatusSave(false);
                setFieldStatusReasonSave(false);
                setDataUpdated(true);
                fetchData();
            } else {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: "Something went wrong!",
                    alertType: AlertType.Error,
                });
            }
            setButtonLoading(false);
        } catch (error) {
            setButtonLoading(false);
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: "Something went wrong!",
                    alertType: AlertType.Error,
                });
            }
        }
        setTimeout(() => {
            updateUserContext({
                ...userContext,
                isAlert: false,
                alertMessage: "",
                alertType: '',
            });
        }, 7000);
        // resetForm(formInitialValues);

    }

    async function fetchData() {
        setLoading(true);
        try {
            let urlparams = new URLSearchParams();
            urlparams.append("huid", userContext.hiagramId.toString());
            urlparams.append("puid", params.dataId.split('-')[1].toString());
            const data: any = await ProjectGetAllDetail(urlparams);
            if (data !== null && data.data !== null && data.data.data !== null) {
                let project: any = data.data.data
                setProjectData(project);
                if (userContext.isItemCountUpdated === true) {
                    setDataUpdated(true);
                }
                const initialValues = {
                    id: project.projectdetail.uid,
                    name: project.projectdetail.name,
                    description: project.projectdetail.description,
                    status: project.projectdetail.status,
                    statusReason: project.projectdetail.proj_status_reason,
                    purpose: project.projectdetail.purpose
                };


                setProjectDetailData(initialValues);

                // let roles: any = project.roles;
                // let roleCounts: any = project.roledata;
                // let rolesData: any[] = [];
                // roles.forEach((role: any) => {
                //     let rolecount: any = roleCounts.find((x: any) => x.roleid === role.uid);
                //     const initSatatic = [
                //         { id: 1, number: rolecount.stakecount, iconSrc: People },
                //         { id: 2, number: rolecount.doccount, iconSrc: Document },
                //         { id: 3, number: rolecount.hintcount, iconSrc: Hint },
                //         { id: 4, number: rolecount.trgcount, iconSrc: TRG },
                //     ];
                //     let roleConfig: any = {
                //         questionName: "activities",
                //         questionTypeCount: rolecount.activitycount,
                //         isAddButton: false,
                //         upperIcon: ActivityUpperNodeIcon,
                //         isUpperLabelVisible: true,
                //         isUpperLabelGridVisible: true,
                //         isBottomLabelVisible: false,
                //         isHoverable: false,
                //         isPopoverHover: false,
                //         isMenuButtonVisible: false,
                //         menuItems: initMenuItems,
                //         questionsData: initSatatic,
                //     };
                //     let newData: any = {
                //         role: role,
                //         config: roleConfig
                //     }
                //     rolesData.push(newData);
                // });
                // setRoleData(rolesData);
            }
            setLoading(false);
            updateUserContext({
                ...userContext,
                isItemCountUpdated: false
            });
        }
        catch (error: any) {
            if (error) {
                setLoading(false);
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }
    useEffect(() => {
        fetchData();
    }, []);



    useEffect(() => {
    }, [projectData]);

    function addProject() {
        let lastQuestionData: any = {
            questionUid: 3,
            totalTeamProj: 0,
            totalRole: 0,
            teamProjName: "",
            teamProjPurpose: "",
            teamProjId: 0,
            isProj: "false",
            roleId: 0,
            roleName: "",
            activityId: 0,
            activityName: "",
            cessId: 0,
            cessName: "",
            stepId: 0,
            stepName: ""
        };
        updateUserContext({
            ...userContext,
            lastQuestionInfo: lastQuestionData
        });
        navigate("/question/" + userContext.hiagramId);
    }

    function addTeam() {
        let lastQuestionData: any = {
            questionUid: 1,
            totalTeamProj: 0,
            totalRole: 0,
            teamProjName: "",
            teamProjPurpose: "",
            teamProjId: 0,
            isProj: "false",
            roleId: 0,
            roleName: "",
            activityId: 0,
            activityName: "",
            cessId: 0,
            cessName: "",
            stepId: 0,
            stepName: ""
        };
        updateUserContext({
            ...userContext,
            lastQuestionInfo: lastQuestionData
        });
        navigate("/question/" + userContext.hiagramId);
    }

    // function addRole() {
    //     let lastQuestionData: any = {
    //         questionUid: 13,
    //         totalTeamProj: 0,
    //         totalRole: 0,
    //         teamProjName: projectDetailData.name,
    //         teamProjPurpose: projectDetailData.purpose,
    //         teamProjId: projectDetailData.id,
    //         isProj: "true",
    //         roleId: 0,
    //         roleName: "",
    //         activityId: 0,
    //         activityName: "",
    //         cessId: 0,
    //         cessName: "",
    //         stepId: 0,
    //         stepName: ""
    //     };
    //     updateUserContext({
    //         ...userContext,
    //         lastQuestionInfo: lastQuestionData
    //     });
    //     navigate("/question/" + userContext.hiagramId);
    // }
    const [statusData, setStatusData] = useState([]);

    async function fetchStatus() {
        try {
            const statusGetData: any = await GetStatusList();
            if (statusGetData !== undefined && statusGetData !== null && statusGetData.data !== null && statusGetData.data.data !== null && statusGetData.data.data.data !== null) {
                setStatusData(statusGetData.data.data.data);
            }
        }
        catch (error: any) {
            if (error) {
                updateUserContext({
                    ...userContext,
                    isAlert: true,
                    alertMessage: error.response.message,
                    alertType: AlertType.Error,
                });
            }
        }
    }
    useEffect(() => {
        fetchStatus()
    }, [])

    useEffect(() => {

        if (openDialogBoxAction === true && childIteamAddActionCompRef.current !== undefined && childIteamAddActionCompRef.current !== null) {
            childIteamAddActionCompRef.current.getData();
        }
        else if (openDialogBoxPeople === true && childIteamAddStakeCompRef.current !== undefined && childIteamAddStakeCompRef.current !== null) {
            childIteamAddStakeCompRef.current.getData();
        }
        else if (openDialogBoxDocument === true && childIteamAddDocCompRef.current !== undefined && childIteamAddDocCompRef.current !== null) {
            childIteamAddDocCompRef.current.getData();
        }
        else if (openDialogBoxHint === true && childIteamAddHintCompRef.current !== undefined && childIteamAddHintCompRef.current !== null) {
            childIteamAddHintCompRef.current.getData();
        }
    })

    // Function to capitalize only the first letter of the string
    function capitalizeWords(str: string) {
        let splitString = str?.replaceAll('_', ' ')?.split(' ')
            .map(word => word?.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
        return splitString
    }

    return (
        <>
            <Grid container className="heading-container-grid-project">
                <Grid className="header-css" item xs={11}>
                    <Typography className='header-icon-grid'>
                        <img loading="lazy" alt='imprend' className='header-icon' src={Project} />
                    </Typography>
                    <Typography className="icon-text">Project: {projectDetailData.name}</Typography>
                    {isAuthorize === true ?
                        <>
                            <Button className="add-btn"
                                onClick={addProject}
                                startIcon={<AddIcon sx={{ color: '#2C2449' }} />}
                            >
                                add Project
                            </Button>
                            <Button className="add-btn"
                                //onClick={()=> params.onClose(isDataUpdated)}
                                onClick={addTeam}
                                startIcon={<AddIcon sx={{ color: '#2C2449' }} />}>
                                add Team
                            </Button>
                        </>
                        : <></>}
                </Grid>
                <Grid item xs={1} className="clear-icon-grid">
                    <Button
                        disableRipple
                        className="btn-cancel"
                        onClick={handleCloseModal}>
                        <ClearIcon sx={{ height: "35px", width: "35px", color: "#292D32" }} />
                    </Button>
                </Grid>
            </Grid>
            {+userContext.tempUid === 3 ?
                <Box className='box-node-project'>
                    <Paper elevation={0} className='paper-project'>
                        <Grid container className='node-component-container-project'>
                            {/* rollup temp close start*/}
                            {/* <Card
                                className='card-component-project'
                                onClick={handleClickPeopleNode}>
                                <NodeComponent
                                    iconSrc={People}
                                    isLoading={isLoading}
                                    text="People"
                                    number={projectData !== undefined ? projectData.stakecount : 0}
                                    border='#807c92'
                                />
                            </Card> */}
                            {/* rollup temp close end */}
                            <Card
                                className='card-component-project'
                                onClick={handleClickActionNode}>
                                <NodeComponent
                                    iconSrc={TimeIcon}
                                    isLoading={isLoading}
                                    text="Milestones"
                                    number={projectData !== undefined ? projectData.actioncount : 0}
                                    // border='#F58A80'
                                    border='#f37368'
                                />
                            </Card>
                        </Grid>
                    </Paper>
                </Box>
                :
                <Box className='box-node-project'>
                    <Paper elevation={0} className='paper-project'>
                        <Grid container className='node-component-container-project'>
                            <Card
                                className='card-component-project'
                                onClick={handleClickPeopleNode}
                            >
                                <NodeComponent
                                    iconSrc={People}
                                    isLoading={isLoading}
                                    text="People"
                                    number={projectData !== undefined ? projectData.stakecount : 0}
                                    border='#807c92'
                                />
                            </Card>
                            <Card
                                className='card-component-project'
                                onClick={handleClickDocumentNode}
                            >
                                <NodeComponent
                                    iconSrc={Document}
                                    isLoading={isLoading}
                                    text="Documents"
                                    number={projectData !== undefined ? projectData.doccount : 0}
                                    border='#94b3d2'
                                />
                            </Card>
                            <Card
                                className='card-component-project'
                                onClick={handleClickHintNode}
                            >
                                <NodeComponent
                                    iconSrc={Hint}
                                    isLoading={isLoading}
                                    text="Hints & Risks"
                                    number={projectData !== undefined ? projectData.hintcount : 0}
                                    border='#f59090'
                                />
                            </Card>
                            <Card
                                className='card-component-project'
                                onClick={handleClickActionNode}
                            >
                                <NodeComponent
                                    iconSrc={TimeIcon}
                                    isLoading={isLoading}
                                    text="Milestones"
                                    number={projectData !== undefined ? projectData.actioncount : 0}
                                    // border='#F58A80'
                                    border='#f37368'
                                />
                            </Card>
                        </Grid>
                    </Paper>
                </Box>}

            <Box className='box-form-container-project'>
                <Formik
                    enableReinitialize
                    initialValues={projectDetailData}
                    onSubmit={(values: any) => {
                        addRow(values);
                    }}
                    validationSchema={formValidation}

                >
                    {(props) => {
                        return (
                            <>
                                <Form onSubmit={props.handleSubmit}>
                                    <Grid container className='first-container-project'>
                                        <Grid item xs={2} className="text-grid-textfield-project">
                                            <Typography className="text-typography-project">Name</Typography>
                                        </Grid>
                                        <Grid className="textfield-grid-project" item xs={8}>
                                            {isLoading === true ?
                                                <Skeleton animation="wave" variant="rounded" width='auto' height={30} />
                                                : <>{isFieldNameEdit === true ?
                                                    <Box className="description-textfield-box">
                                                        <TextField
                                                            id="name"
                                                            fullWidth
                                                            value={props.values.name}
                                                            placeholder='Name'
                                                            autoComplete="off"
                                                            inputProps={{ maxLength: 100 }}
                                                            onBlur={() => setFocusedName(false)}
                                                            onFocus={() => setFocusedName(true)}
                                                            onChange={(e: any) => {
                                                                props.setFieldValue(
                                                                    `name`,
                                                                    e.target.value !== null ? e.target.value : "",
                                                                    true
                                                                );
                                                                if (e.target.value !== null && e.target.value !== undefined) {
                                                                    if (e.target.value !== projectDetailData.name) {
                                                                        setFieldNameSave(true);
                                                                    } else {
                                                                        setFieldNameSave(false);
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                        </TextField>
                                                        {isFocusedName && <Typography className="textfield-counter">{(props.values.name !== null ? props.values.name.length : 0) + "/" + 100}</Typography>}
                                                    </Box>
                                                    : <Typography className="text-typography-description-project">{props.values.name}</Typography>}
                                                </>}
                                        </Grid>
                                        <Grid item xs={2} className='edit-btn-grid-project'>
                                            {isLoading === true ? <></> :
                                                userContext.isEditAccess === true ?
                                                    <>
                                                        {isFieldNameEdit === false ?
                                                            <Button
                                                                variant='contained'
                                                                className='edit-btn-project'
                                                                onClick={editName}>
                                                                <img loading="lazy" alt='imprend' src={editIcon} />
                                                            </Button> :
                                                            isFieldNameSave === true ?
                                                                <Box className='proj-card-button'>
                                                                    <Button
                                                                        variant='contained'
                                                                        className='tick-btn-project'
                                                                        type='submit'
                                                                    >
                                                                        {isButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <img loading="lazy" src={tickicon} alt="imprend" />}
                                                                    </Button>
                                                                    <Button
                                                                        variant='contained'
                                                                        className='button-delete-project'
                                                                        onClick={() => cancelName(props)}
                                                                    >
                                                                        {isDeleteButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <ClearIcon sx={{ height: "23px", width: "23px", color: "#ffffff" }} />}
                                                                    </Button>
                                                                </Box>
                                                                : <Button
                                                                    variant='contained'
                                                                    className='button-delete-project'
                                                                    onClick={() => cancelName(props)}
                                                                >
                                                                    {isDeleteButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <ClearIcon sx={{ height: "23px", width: "23px", color: "#ffffff" }} />}
                                                                </Button>
                                                        }</>
                                                    : <></>
                                            }
                                        </Grid>
                                    </Grid>
                                    {+userContext.tempUid === 3 ? <></> :
                                        <Grid container className='middle-container-project'>
                                            <Grid item xs={2} className="text-grid-textfield-project">
                                                <Typography className="text-typography-project">Purpose</Typography>
                                            </Grid>
                                            <Grid className="textfield-grid-project" item xs={8}>
                                                {isLoading === true ?
                                                    <Skeleton animation="wave" variant="rounded" width='auto' height={30} />
                                                    : <>{isFieldPurposeEdit === true ?
                                                        <Box className="description-textfield-box">
                                                            <TextField
                                                                id="purpose"
                                                                fullWidth
                                                                value={props.values.purpose}
                                                                // placeholder='Contrary to popular belief'
                                                                placeholder='Purpose'
                                                                autoComplete="off"
                                                                inputProps={{ maxLength: 30 }}
                                                                onBlur={() => setFocusedPurpose(false)}
                                                                onFocus={() => setFocusedPurpose(true)}
                                                                // onChange={props.handleChange("name")}
                                                                onChange={(e: any) => {
                                                                    props.setFieldValue(
                                                                        // "raci",
                                                                        `purpose`,
                                                                        e.target.value !== null ? e.target.value : "",
                                                                        true
                                                                    );
                                                                    if (e.target.value !== null && e.target.value !== undefined) {
                                                                        if (e.target.value !== projectDetailData.purpose) {
                                                                            setFieldPurposeSave(true);
                                                                        } else {
                                                                            setFieldPurposeSave(false);
                                                                        }
                                                                    }
                                                                }}
                                                            >
                                                            </TextField>
                                                            {isFocusedPurpose && <Typography className="textfield-counter">{(props.values.purpose !== null ? props.values.purpose.length : 0) + "/" + 30}</Typography>}
                                                        </Box>
                                                        : <Typography className="text-typography-description-project">{props.values.purpose}</Typography>}
                                                    </>}
                                            </Grid>
                                            <Grid item xs={2} className='edit-btn-grid-project'>
                                                {isLoading === true ? <></> :
                                                    userContext.isEditAccess === true ?
                                                        <>
                                                            {isFieldPurposeEdit === false ?
                                                                <Button
                                                                    variant='contained'
                                                                    className='edit-btn-project'
                                                                    onClick={editPurpose}>
                                                                    <img loading="lazy" alt='imprend' src={editIcon} />
                                                                </Button> :
                                                                isFieldPurposeSave === true ?
                                                                    <Box className='proj-card-button'>
                                                                        <Button
                                                                            variant='contained'
                                                                            className='tick-btn-project'
                                                                            type='submit'
                                                                        >
                                                                            {isButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <img loading="lazy" src={tickicon} alt="imprend" />}
                                                                        </Button>
                                                                        <Button
                                                                            variant='contained'
                                                                            className='button-delete-project'
                                                                            onClick={() => cancelPurpose(props)}
                                                                        >
                                                                            {isDeleteButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <ClearIcon sx={{ height: "23px", width: "23px", color: "#ffffff" }} />}
                                                                        </Button>
                                                                    </Box>
                                                                    : <Button
                                                                        variant='contained'
                                                                        className='button-delete-project'
                                                                        onClick={() => cancelPurpose(props)}
                                                                    >
                                                                        {isDeleteButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <ClearIcon sx={{ height: "23px", width: "23px", color: "#ffffff" }} />}
                                                                    </Button>
                                                            }</>
                                                        : <></>
                                                }
                                            </Grid>
                                        </Grid>}

                                    <Grid container className='second-container-project' sx={{ marginTop: 2 }}>
                                        <Grid item xs={2} className="text-grid-textfield-description-project">
                                            <Typography className="text-typography-project">Description</Typography>
                                        </Grid>
                                        <Grid item xs={8} className="textfield-grid-project" >
                                            {isLoading === true ?
                                                <Skeleton animation="wave" variant="rounded" width='auto' height={60} />
                                                : <> {isFieldDescriptionEdit === true ?
                                                    <Box className="description-textfield-box">
                                                        <TextField
                                                            id="description"
                                                            multiline={true}
                                                            rows={6}
                                                            className="description-textfield-project"
                                                            inputProps={{ maxLength: 5000 }}
                                                            fullWidth
                                                            onBlur={() => setFocusedDescription(false)}
                                                            onFocus={() => setFocusedDescription(true)}
                                                            // onChange={props.handleChange("description")}
                                                            onChange={(e: any) => {
                                                                props.setFieldValue(
                                                                    // "raci",
                                                                    `description`,
                                                                    e.target.value !== null ? e.target.value : "",
                                                                    true
                                                                );
                                                                if (e.target.value !== null && e.target.value !== undefined) {
                                                                    if (e.target.value !== projectDetailData.description) {
                                                                        setFieldDescriptionSave(true);
                                                                    } else {
                                                                        setFieldDescriptionSave(false);
                                                                    }
                                                                }
                                                            }}
                                                            value={props.values.description}
                                                            placeholder='Description'
                                                            autoComplete="off"
                                                        >
                                                        </TextField>
                                                        {isFocusedDescription && <Typography className="textfield-counter">{(props.values.description !== null ? props.values.description.length : 0) + "/" + 5000}</Typography>}
                                                    </Box>
                                                    : <Typography className='text-typography-description-project'>{props.values.description}</Typography>}
                                                </>}
                                        </Grid>
                                        <Grid item xs={2} className='edit-btn-grid-project'>
                                            {isLoading === true ? <></> :
                                                userContext.isEditAccess === true ?
                                                    <>
                                                        {isFieldDescriptionEdit === false ?
                                                            <Button
                                                                variant='contained'
                                                                className='edit-btn-project'
                                                                onClick={editDescription}
                                                            >
                                                                <img loading="lazy" alt='imprend' src={editIcon} />
                                                            </Button> :
                                                            isFieldDescriptionSave === true ?
                                                                <Box className='proj-card-button'>
                                                                    <Button
                                                                        variant='contained'
                                                                        className='tick-btn-project'
                                                                        //onClick={addRow}
                                                                        type='submit'
                                                                    >
                                                                        {isButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <img loading="lazy" src={tickicon} alt="imprend" />}
                                                                    </Button>
                                                                    <Button
                                                                        variant='contained'
                                                                        className='button-delete-project'
                                                                        onClick={() => cancelDescription(props)}
                                                                    >
                                                                        {isDeleteButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <ClearIcon sx={{ height: "23px", width: "23px", color: "#ffffff" }} />}
                                                                    </Button>
                                                                </Box> :
                                                                <Button
                                                                    variant='contained'
                                                                    className='button-delete-project'
                                                                    onClick={() => cancelDescription(props)}
                                                                >
                                                                    {isDeleteButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <ClearIcon sx={{ height: "23px", width: "23px", color: "#ffffff" }} />}
                                                                </Button>

                                                        }</>
                                                    : <></>
                                            }
                                        </Grid>
                                    </Grid>

                                    {/* status */}
                                    <Grid container className='second-container-project' sx={{ marginTop: 2 }}>
                                        <Grid item xs={2} className="text-grid-textfield-description-project">
                                            <Typography className="text-typography-project">
                                                Status
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={8} className="textfield-grid-project" >
                                            {isLoading === true ?
                                                <Skeleton animation="wave" variant="rounded" width='auto' height={60} />
                                                : <> {isFieldStatusEdit === true ?
                                                    <Box className="description-textfield-box">
                                                        <Select
                                                            className="status-dropdown-project"
                                                            classNamePrefix="select"
                                                            isDisabled={!userContext.isEditAccess}
                                                            isClearable={true}
                                                            isSearchable={true}
                                                            placeholder={''}
                                                            components={{
                                                                IndicatorSeparator: () => null
                                                            }}
                                                            options={statusData}
                                                            onChange={(e: any) => {
                                                                props.setFieldValue(
                                                                    `status`,
                                                                    e !== null ? e.value : "",
                                                                    false
                                                                );
                                                                if (e?.label !== null && e?.label !== undefined) {
                                                                    if (e.value !== projectDetailData.status) {
                                                                        setFieldStatusSave(true);
                                                                    } else {
                                                                        setFieldStatusSave(false);
                                                                    }
                                                                }
                                                            }}
                                                            value={getSingleSelected(
                                                                props.values.status,
                                                                statusData
                                                            )}
                                                            theme={(theme: any) => ({
                                                                ...theme,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary: mainTheme.palette.primary.main,
                                                                    primary25: '#FEF4F4',
                                                                    primary50: '#FEF4F4',
                                                                },
                                                            })}
                                                            styles={selectStyles}
                                                        />
                                                    </Box>
                                                    : <Typography className='text-typography-description-project'>{capitalizeWords(props?.values?.status)}</Typography>}
                                                </>}
                                        </Grid>
                                        <Grid item xs={2} className='edit-btn-grid-project'>
                                            {isLoading === true ? <></> :
                                                userContext.isEditAccess === true ?
                                                    <>
                                                        {isFieldStatusEdit === false ?
                                                            <Button
                                                                variant='contained'
                                                                className='edit-btn-project'
                                                                onClick={editStatus}
                                                            >
                                                                <img loading="lazy" alt='imprend' src={editIcon} />
                                                            </Button> :
                                                            isFieldStatusSave === true ?
                                                                <Box className='proj-card-button'>
                                                                    <Button
                                                                        variant='contained'
                                                                        className='tick-btn-project'
                                                                        //onClick={addRow}
                                                                        type='submit'
                                                                    >
                                                                        {isButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <img loading="lazy" src={tickicon} alt="imprend" />}
                                                                    </Button>
                                                                    <Button
                                                                        variant='contained'
                                                                        className='button-delete-project'
                                                                        onClick={() => cancelStatus(props)}
                                                                    >
                                                                        {isDeleteButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <ClearIcon sx={{ height: "23px", width: "23px", color: "#ffffff" }} />}
                                                                    </Button>
                                                                </Box> :
                                                                <Button
                                                                    variant='contained'
                                                                    className='button-delete-project'
                                                                    onClick={() => cancelStatus(props)}
                                                                >
                                                                    {isDeleteButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <ClearIcon sx={{ height: "23px", width: "23px", color: "#ffffff" }} />}
                                                                </Button>
                                                        }</>
                                                    : <></>
                                            }
                                        </Grid>
                                    </Grid>
                                    {/* statusReason */}
                                    <Grid container className='second-container-project' sx={{ marginTop: 2 }}>
                                        <Grid item xs={2} className="text-grid-textfield-status-reason-project">
                                            <Typography className="text-typography-project-status-reason">Status Reason</Typography>
                                        </Grid>
                                        <Grid item xs={8} className="textfield-grid-project" >
                                            {isLoading === true ?
                                                <Skeleton animation="wave" variant="rounded" width='auto' height={60} />
                                                : <> {isFieldStatusReasonEdit === true ?
                                                    <Box className="description-textfield-box">
                                                        <TextField
                                                            id="proj_status_reason"
                                                            multiline={true}
                                                            rows={6}
                                                            className="description-textfield-project"
                                                            inputProps={{ maxLength: 5000 }}
                                                            fullWidth
                                                            onBlur={() => setFocusedStatusReason(false)}
                                                            onFocus={() => setFocusedStatusReason(true)}
                                                            onChange={(e: any) => {
                                                                props.setFieldValue(
                                                                    `statusReason`,
                                                                    e.target.value !== null ? e.target.value : "",
                                                                    true
                                                                );
                                                                if (e.target.value !== null && e.target.value !== undefined) {
                                                                    if (e.target.value !== projectDetailData.statusReason) {
                                                                        setFieldStatusReasonSave(true);
                                                                    } else {
                                                                        setFieldStatusReasonSave(false);
                                                                    }
                                                                }
                                                            }}
                                                            value={props.values.statusReason}
                                                            placeholder='Status reason'
                                                            autoComplete="off"
                                                        >
                                                        </TextField>
                                                        {isFocusedStatusReason && <Typography className="textfield-counter">{(props.values.statusReason !== null ? props.values.statusReason.length : 0) + "/" + 5000}</Typography>}
                                                    </Box>
                                                    : <Typography className='text-typography-description-project'>{props.values.statusReason}</Typography>}
                                                </>}
                                        </Grid>
                                        <Grid item xs={2} className='edit-btn-grid-project'>
                                            {isLoading === true ? <></> :
                                                userContext.isEditAccess === true ?
                                                    <>
                                                        {isFieldStatusReasonEdit === false ?
                                                            <Button
                                                                variant='contained'
                                                                className='edit-btn-project'
                                                                onClick={editStatusReason}
                                                            >
                                                                <img loading="lazy" alt='imprend' src={editIcon} />
                                                            </Button> :
                                                            isFieldStatusReasonSave === true ?
                                                                <Box className='proj-card-button'>
                                                                    <Button
                                                                        variant='contained'
                                                                        className='tick-btn-project'
                                                                        //onClick={addRow}
                                                                        type='submit'
                                                                    >
                                                                        {isButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <img loading="lazy" src={tickicon} alt="imprend" />}
                                                                    </Button>
                                                                    <Button
                                                                        variant='contained'
                                                                        className='button-delete-project'
                                                                        onClick={() => cancelStatusReason(props)}
                                                                    >
                                                                        {isDeleteButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <ClearIcon sx={{ height: "23px", width: "23px", color: "#ffffff" }} />}
                                                                    </Button>
                                                                </Box> :
                                                                <Button
                                                                    variant='contained'
                                                                    className='button-delete-project'
                                                                    onClick={() => cancelStatusReason(props)}
                                                                >
                                                                    {isDeleteButtonLoading === true ? <CircularProgress size={16} sx={{ color: "white" }} /> : <ClearIcon sx={{ height: "23px", width: "23px", color: "#ffffff" }} />}
                                                                </Button>
                                                        }</>
                                                    : <></>
                                            }
                                        </Grid>
                                    </Grid>

                                    {/* {isFieldEditCommon === true && isAuthorize === true ? */}
                                    {/* {userContext.isEditAccess === true ?
                                        <>
                                            {isFieldEdit === true || isFieldDescriptionEdit === true ?
                                                <Grid container className='last-container'>
                                                    <Grid item xs={2} sx={{ maxWidth: "95px !important" }}></Grid>
                                                    <Grid item xs={9} className="textfield-grid">
                                                        <Button
                                                            className='add-btn'
                                                            type='submit'
                                                        >
                                                            Save</Button>
                                                    </Grid>
                                                </Grid>
                                                : <></>} </>
                                        : <></>} */}
                                </Form>
                            </>
                        )
                    }}
                </Formik>
            </Box>
            {/* <Grid container className={isFieldEditCommon === true ? "box-container-add-role" : "box-container-add-role-visible"}>
                <Grid className="role-header-proj" item xs={11}>
                    <Typography className='header-icon-grid'>
                        <img loading="lazy" alt='imprend' className='header-icon' src={BreifIcon} />
                    </Typography>
                    <Typography className="icon-text">Roles</Typography>
                    {userContext.isEditAccess === true ?
                        <Button className="add-btn"
                            onClick={addRole}
                            startIcon={<AddIcon sx={{ color: '#2C2449' }} />}
                        >
                            add Role
                        </Button>
                        : <></>}
                </Grid>
            </Grid>
            <Box sx={{ marginTop: 2 }}>
                <Grid container className='role-grid-container-project'>
                    {isLoading === true ? <>
                        <Grid item xs={3}>
                            <Skeleton animation="wave" variant="rounded" width={80} height={30} />
                            <br /><Skeleton animation="wave" variant="rounded" width={170} height={20} />
                        </Grid>
                        <Grid item xs={6}>
                            <Skeleton animation="wave" variant="rounded" width={100} height={30} />
                            <br /><Skeleton animation="wave" variant="rounded" width={500} height={20} />
                            <br /><Skeleton animation="wave" variant="rounded" width={300} height={20} />
                        </Grid>
                        {false ?
                            <Grid item xs={1} className='icon-button-container '>
                                <Button
                                    variant='contained'
                                    className='edit-btn'
                                >
                                    <img loading="lazy" alt='imprend' src={editIcon} />
                                </Button>
                                <Button
                                    variant='contained'
                                    className='delete-btn'
                                >
                                    <img loading="lazy" alt='imprend' src={trashIcon} />
                                </Button>
                            </Grid>
                            : <></>}</>
                        : <>
                            {roleData !== undefined && roleData.length > 0 && roleData.map((row, index) => (
                                <Grid container key={index} className='role-grid-item-container'>
                                    <Grid item xs={3}>
                                        <Typography className='typography-heading'>Name</Typography>
                                        <Typography className='typography-description'>{row !== undefined ? row.role.name : ""}</Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography className='typography-heading'>Description</Typography>
                                        <Typography className='typography-description'>{row !== undefined ? row.role.description : ""}</Typography>
                                    </Grid>

                                    <Grid item xs={1} className='icon-button-container '>
                                        {userContext.isEditAccess === true ? <>
                                            <Button
                                                variant='contained'
                                                className='edit-btn'
                                                onClick={() => handleClickOpenForRoleEdit(row.role.uid)}
                                            >
                                                <img loading="lazy" alt='imprend' src={editIcon} />
                                            </Button>
                                            <Button
                                                variant='contained'
                                                className='delete-btn'
                                                onClick={() => handleClickOpenForRoleDelete(row.role.uid)}
                                            >
                                                <img loading="lazy" alt='imprend' src={trashIcon} />
                                            </Button></> : <></>}
                                    </Grid>

                                </Grid>))}</>}
                    {roleData !== undefined && roleData.length === 0 ?
                        <Box className='role-no-data'>
                            <Typography className='role-no-data-text'>None added yet!</Typography>
                        </Box> : <></>
                    }
                </Grid>
            </Box> */}

            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={openDialogBoxDocument}
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseDocumentNode}
            >
                <DialogContent
                    className='dalog-box-content'
                // sx={{ paddingBottom: "50px" }}
                >
                    <ItemDocAdd onClose={handleCloseDocumentNode}
                        QuickView={true}
                        curQuestion={curQuestionDoc}
                        ref={childIteamAddDocCompRef}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={openDialogBoxHint}
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseHintNode}

            >
                <DialogContent
                    className='dalog-box-content'
                // sx={{ paddingBottom: "50px" }}
                >
                    <ItemHintAdd onClose={handleCloseHintNode}
                        QuickView={true}
                        curQuestion={curQuestionHint}
                        ref={childIteamAddHintCompRef}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={openDialogBoxPeople}
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClosePeopleNode}

            >
                <DialogContent
                    className='dalog-box-content'
                // sx={{ paddingBottom: "50px" }}
                >
                    <ItemStakeAdd onClose={handleClosePeopleNode}
                        QuickView={true}
                        curQuestion={curQuestionStack}
                        directlyOpenFromTeamProj={true}
                        ref={childIteamAddStakeCompRef}
                        parentNodeName="PROJ"
                        parentNodeId={projectData !== undefined && projectData !== null && projectData.projectdetail.uid}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={openDialogBoxAction}
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseActionNode}
            >
                <DialogContent
                    className='dalog-box-content'
                // sx={{ paddingBottom: "50px" }}
                >
                    <ItemActionAdd onClose={handleCloseActionNode}
                        QuickView={true}
                        curQuestion={curQuestionAction}
                        ref={childIteamAddActionCompRef}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                open={openDeleteModal}
                //onClose={handleCloseDeleteModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='delete-client-dialog-box'
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className='popup-text'>
                        Are you sure? Your current unsaved changes will be lost.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        variant='outlined'
                        // onClick={deleteNodeEdges}
                        onClick={() => handleCloseDeleteModal(true)}
                        className="popup-btn"
                    // loading={deleteLoading}
                    >
                        Yes
                    </LoadingButton>
                    <Button
                        variant='contained'
                        onClick={() => handleCloseDeleteModal(false)}
                        className="popup-btn"
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>

            {/* <Dialog
                maxWidth={"md"}
                fullWidth={true}
                open={openRoleEditDialog}
                scroll='body'
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseRoleEditPopup}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent>
                    <Role
                        id={roleEditId}
                        onClose={handleCloseRoleEditPopup}
                        onSubmitClose={handleSubmitClose}
                    //ref={childRoleEditCompRef}
                    />
                </DialogContent>
            </Dialog> */}

            {/* <Dialog
                open={openRoleDeleteDialog}
                onClose={handleCloseRoleDeleteModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='delete-client-dialog-box'
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className='popup-text'>
                        WARNING! Deleting this Role will also delete any Activities and Processes beneath it.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        variant="outlined"
                        onClick={() => deleteRole()}
                        className="popup-btn"
                        loading={isBtnLoading}
                    >
                        Yes
                    </LoadingButton>
                    <Button
                        variant='contained'
                        onClick={handleCloseRoleDeleteModal}
                        className="popup-btn"
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog> */}

        </>
    )
}
const selectStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        background: '#FFFFFF'
    })
};
import React, { memo, useContext, useEffect, useState } from 'react';
import { Handle, Position, NodeProps, useReactFlow, getOutgoers, MarkerType, getIncomers, useOnSelectionChange, useNodeId } from 'reactflow';
import { hierarchy as d3Hierarchy, tree as d3Tree } from 'd3-hierarchy';
import cx from 'classnames';
import AddIcon from '@mui/icons-material/Add';
import styles from './NodeTypes.module.css';
import useNodeAddChild from '../../hooks/useNodeAddChild';
import useNodeAddParent from '../../hooks/useNodeAddParent';
import useNodeAddConcurrent from '../../hooks/useNodeAddConcurrent';
import useNodeClickDelete from '../../hooks/useNodeDelete';
import Process from '../../assets/icons/processblue.svg'
import Add from '../../assets/icons/add-black.svg'
import Edit from '../../assets/icons/edit.svg'
import DeleteIcon from '../../assets/icons/trash-step.svg'
import AssistIcon from '../../assets/icons/assist-sq.svg'
import DocIcon from '../../assets/icons/doc-sq.svg'
import HintIcon from '../../assets/icons/hint-sq.svg'
import StakeIcon from '../../assets/icons/stake-sq.svg'
import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, MenuItem, MenuList, Paper, TextField, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useFocusNode from '../../hooks/useFocusNode';
import { useNodeOpenMenu } from '../../hooks/useNodeOpenMenu';
import LoadingButton from '@mui/lab/LoadingButton';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import { PreferencesContext } from '../../PreferenceContext';
import { AlertType } from '../../shared/AlertType';
import { AddStep, AddStepEdge, StepEdgeList, UpdateStep, UpdateSteps } from '../../services/StepService';

const formInitialValues = {
  answerId: 0,
  description: "",
  title: "",
  stepId: 0
};

const curStep = {
  name: "",
  description: "",
};

type DataType = {
  nodeId?: string;
};


const StepNode = ({ id, data }: NodeProps) => {
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  const [hierarchy, setHierarchy] = useState(d3Hierarchy<DataType>(data));
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAddEditModal, setOpenAddEditModal] = useState(false);
  const [addEditModalTitle, setAddEditModalTitle] = useState("");
  const [nodeAddType, setNodeAddType] = useState("");
  const [forminitial, setFormInitial] = useState(formInitialValues);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isFocusedName, setFocusedName] = useState(false);
  const [isFocusedDescription, setFocusedDescription] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [curStepData, setCurStepData] = useState(curStep);
  const formValidation = Yup.object().shape({
    title: Yup.string().required("Step title is required."),
  });
  const { setEdges, setNodes, getNodes, getEdges, getNode, getEdge } = useReactFlow();
  const addAfter = useNodeAddChild(id, "", "");
  const addBefore = useNodeAddParent(id);
  const addConcurrent = useNodeAddConcurrent(id);
  const deleteNode = useNodeClickDelete(id);
  const SelectNode = useFocusNode(id);
  const setMenu = useNodeOpenMenu(id);
  const setCloseMenu = useNodeOpenMenu(id, false);
  let removeNodes: any[] = [];
  let removeEdges: any[] = [];
  const currentNode: any = getNode(id);
  const visible = Boolean(anchorEl);
  // const layout = d3Tree<DataType>().nodeSize([350, 180]);
  // const mainTheme = useTheme();
  // const nodeId = useNodeId();

  function getElements() {
    let tempHierarchy = hierarchy;
    // tempHierarchy.descendants().forEach((d: any, i: any) => {
    //   // d.data.expanded = d.data.dataId === "0" ? true : false;
    //   // d.data.unexpanded = false;
    //   // d.data.id = `${i}`;
    //   // d.data.children = d.children;
    //   // // d.data.name = d.data.name;
    //   // // d.data.colourType = d.data.colourType;
    //   // d.data.allexpanded = false;
    //   // d.children = undefined;
    // });
    let counter = 1; // Initialize a counter

    tempHierarchy.descendants().forEach((d: any) => {
      // Assigning nodeId to each object
      d.data.nodeId = counter++;
      // Other modifications or operations as needed
    });

    // console.log(tempHierarchy.descendants());
  }

  useEffect(() => {
    getElements();
  })

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    // setAnchorEl(event.currentTarget);

    if (currentNode) {
      const hoverValues = {
        description: currentNode.data.description,
        name: currentNode.data.title
      };
      setCurStepData(hoverValues);
    }
  }

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  async function removeNodeandEdges(childs: any[], currentNodeId: any) {
    childs.forEach(child => {
      //if (child.id !== '2') {
      let currentEdge: any = currentNodeId + "=>" + child.id;
      // removeEdges.push(...removeEdges, currentEdge);
      removeEdges.push(currentEdge);
      const childParents = getIncomers(child, getNodes(), getEdges()).filter((node) => node.id !== currentNodeId);
      let childParentsNode = [];
      if (childParents.length > 0) {
        childParentsNode = childParents.filter(parent => !removeNodes.some(removedNode => (removedNode.id === parent.id)))
      }

      if (childParentsNode.length === 0) {
        if (child.id !== '2') {
          removeNodes.push({ ...child });
        }
        const innerChilds = getOutgoers(child, getNodes(), getEdges());
        if (innerChilds.length > 0) {
          removeNodeandEdges(innerChilds, child.id);
        }
      }
      //}
    });
  }

  async function getAllStepEdges() {
    let params = new URLSearchParams();
    params.append("huid", userContext.hiagramId.toString());
    params.append("cuid", data.cessId.toString());
    let allEdges: any = [];
    //let allEdges = getEdges();
    let listData: any = await StepEdgeList(params);
    if (listData !== null && listData.data !== null && listData.data.data !== null) {
      let allStepEdgeListData = listData.data.data as any[];
      if (allStepEdgeListData.length > 0) {
        allStepEdgeListData.forEach(stepEdgeData => {
          const newEdge = {
            id: stepEdgeData.name,
            source: stepEdgeData.name.split("=>")[0],
            target: stepEdgeData.name.split("=>")[1],
            data: { uid: stepEdgeData.uid, cessId: data.cessId },
            type: 'step',
            style: { stroke: '#680001', strokeDasharray: 3 },
            markerEnd: {
              type: MarkerType.Arrow,
              color: '#680001',
              height: 20,
              width: 20,
            },
          };
          allEdges.push(newEdge);
          // setEdges((edges) =>
          //     edges.concat([newEdge])
          // );
        });
        setEdges(allEdges);
      }
    }
    return allEdges;
  }

  async function deleteNodeEdges() {
    setDeleteLoading(true);
    removeNodes = [];
    removeEdges = [];
    try {
      const currentNode = getNode(id);
      if (!currentNode) {
        return;
      }
      const parentNodes = getIncomers(currentNode, getNodes(), getEdges());
      if (parentNodes.length > 0) {
        parentNodes.forEach((node) => {
          let edge = node.id + "=>" + id;
          removeEdges.push(edge);
        });
      }

      const childs = getOutgoers(currentNode, getNodes(), getEdges());

      if (childs.length > 0) {
        removeNodeandEdges(childs, id);
      }
      removeNodes.push(currentNode);
      setNodes((nodes) =>
        nodes.filter((node) => node.id !== currentNode.id));
      //Remove Child Nodes
      if (removeNodes.length > 0) {
        removeNodes.forEach(rNodes => {
          if (rNodes.id !== '2') {
            setNodes((nodes) =>
              nodes.filter((node) => node.id !== rNodes.id));
          }
        });
      }
      // let sdfjv = getEdges();
      // let curEndEdgeId = id + "=>2";
      // let curEndEdge = getEdge(curEndEdgeId);
      // if (curEndEdge !== undefined) {
      //   removeEdges.push(curEndEdgeId);
      // }

      let allEdgeData = getEdges();
      //Remove Child Edges
      if (removeEdges.length > 0) {
        removeEdges.forEach(rEdges => {
          setEdges((edges) =>
            edges.filter((edge) => edge.id !== rEdges));
        });
      }



      let removeEdgesCol: any[] = [];
      //Remove all end edges
      const endNode = getNode('2');
      if (endNode) {
        const allParentIds = getIncomers(endNode, getNodes(), getEdges()).map((node) => node.id);
        if (allParentIds.length > 0) {
          allParentIds.forEach(node => {
            removeEdgesCol.push(allEdgeData.find((x) => x.id === `${node}=>2`));
            setEdges((edges) =>
              edges.filter((edge) => edge.id !== `${node}=>2`));
          });
        }
      }

      let addEdgesCol: any[] = [];
      //Connect to end node
      const allNodes = getNodes();

      if (allNodes.length > 0) {
        allNodes.forEach(node => {
          const allChilds = getOutgoers(node, getNodes(), getEdges());
          if (allChilds.length === 0 && node.id !== '2') {
            const endEdge = {
              id: `${node.id}=>2`,
              source: node.id,
              target: '2',
              data: { uid: 0, cessId: data.cessId },
              type: 'step',
              style: { stroke: '#680001', strokeDasharray: 3 },
              markerEnd: {
                type: MarkerType.Arrow,
                color: '#680001',
                height: 20,
                width: 20,
              },
            };
            if (endEdge.id !== "1=>2") {
              addEdgesCol.push(endEdge);
              setEdges((edges) =>
                edges.concat([endEdge])
              );
            }
          }
        });
        if (allNodes.length === 2) {
          setNodes((nodes) =>
            nodes.filter((node) => node.id !== '2'));
        }
      }


      let requestNodeData: any[] = [];
      if (removeNodes.length > 0) {
        removeNodes.forEach(rNodes => {
          if (rNodes.id !== '2') {
            let nodeData = {
              stepUid: rNodes.id,
              hiagramUid: userContext.hiagramId,
              cessUid: data.cessId,
              name: rNodes.title,
              description: rNodes.description,
              position: (rNodes.position.x + "," + (rNodes.position.y)).toString(),
              isDeleted: true,
              dataManagedBy: userContext.userId
            }
            requestNodeData.push(nodeData);
          }
        });
      }
      let requestData: any[] = [];
      if (removeEdgesCol.length > 0) {
        removeEdgesCol.forEach(edge => {
          let edgeData = {
            stepEdgeUid: edge.data.uid,
            hiagramUid: userContext.hiagramId,
            cessUid: data.cessId,
            name: edge.id,
            isDeleted: true,
            dataManagedBy: userContext.userId
          }
          requestData.push(edgeData);
        });
      }
      if (addEdgesCol.length > 0) {
        addEdgesCol.forEach(edge => {
          let edgeData = {
            stepEdgeUid: 0,
            hiagramUid: userContext.hiagramId,
            cessUid: data.cessId,
            name: edge.id,
            isDeleted: false,
            dataManagedBy: userContext.userId
          }
          requestData.push(edgeData);
        });
      }
      if (removeEdges.length > 0) {
        removeEdges.forEach(rEdge => {
          let tempEdge: any = allEdgeData.find((x) => x.id === rEdge);
          if (tempEdge !== undefined) {
            let edgeData = {
              stepEdgeUid: tempEdge.data.uid,
              hiagramUid: userContext.hiagramId,
              cessUid: data.cessId,
              name: tempEdge.id,
              isDeleted: true,
              dataManagedBy: userContext.userId
            }
            requestData.push(edgeData);
          }
        })
      }

      if (requestData.length > 0) {
        let edgeAPIData = await AddStepEdge(requestData);
        getAllStepEdges();
        //console.log(requestData);
      }
      if (requestNodeData.length > 0) {
        let nodeAPIData = await UpdateSteps(requestNodeData);
        //console.log(requestNodeData);
      }

      setOpenDeleteModal(false);
      setDeleteLoading(false);
    }
    catch (error: any) {
      setOpenDeleteModal(false);
      setDeleteLoading(false);
      if (error) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: error.response.message,
          alertType: AlertType.Error,
        });
      }
    }

  }

  const onDeleteClick = () => {
    setOpenDeleteModal(true);
    openCloseMenu();
  }

  async function addAfterHook(title: any, description: any) {
    setLoading(true);
    let allEdgesData: any[] = [];
    try {
      const parentNode = getNode(id);

      if (!parentNode) {
        return;
      }
      if (id === '1') {
        const allStartChilds = getOutgoers(parentNode, getNodes(), getEdges()).filter((node) => node.id !== '2');
        if (allStartChilds !== null && allStartChilds !== undefined && allStartChilds.length > 0) {
          return;
        }
      }

      let requestData = {
        stepUid: 0,
        hiagramUid: userContext.hiagramId,
        cessUid: data.cessId,
        name: title,
        description: description,
        position: (parentNode.position.x + "," + (parentNode.position.y + 200)).toString(),
        isDeleted: false,
        dataManagedBy: userContext.userId
      }
      let nodeAPIdata: any;
      nodeAPIdata = await AddStep(requestData);

      if (nodeAPIdata !== null && nodeAPIdata.data !== null) {

        //const childNodeId = uuid();

        const childNodeId = nodeAPIdata.data.toString();

        const childNode = {
          id: childNodeId,
          position: { x: parentNode.position.x, y: parentNode.position.y + 200 },
          type: 'step',
          data: { title: title, isMenuOpen: false, description: description, cessId: data.cessId, stakeCount: 0, hintCount: 0, assetCount: 0, docCount: 0 },
        };

        const childEdge = {
          id: `${parentNode.id}=>${childNodeId}`,
          source: parentNode.id,
          target: childNodeId,
          data: { uid: 0, cessId: data.cessId },
          type: 'step',
          style: { stroke: '#680001', strokeDasharray: 3 },
          markerEnd: {
            type: MarkerType.Arrow,
            color: '#680001',
            height: 20,
            width: 20,
          },
        };
        allEdgesData.push(childEdge);

        //add mapping to all parent
        const allParentIds = getIncomers(parentNode, getNodes(), getEdges()).map((node) => node.id);

        const topParentNode = getNode(allParentIds[0]);

        if (topParentNode) {
          const allChilds = getOutgoers(topParentNode, getNodes(), getEdges());
          if (allChilds.length > 0) {
            //Connect with parents
            allChilds.forEach(child => {
              if (child.id !== id) {
                const childEdge = {
                  id: `${child.id}=>${childNodeId}`,
                  source: child.id,
                  target: childNodeId,
                  data: { uid: 0, cessId: data.cessId },
                  type: 'step',
                  style: { stroke: '#680001', strokeDasharray: 3 },
                  markerEnd: {
                    type: MarkerType.Arrow,
                    color: '#680001',
                    height: 20,
                    width: 20,
                  },
                };
                allEdgesData.push(childEdge);
                // setEdges((edges) =>
                //   edges.concat([childEdge])
                // );
              }
            });

            //Connect with parent childs
            const allInnerChilds = getOutgoers(allChilds[0], getNodes(), getEdges());
            if (allInnerChilds.length > 0) {
              const allMapChilds = getOutgoers(allInnerChilds[0], getNodes(), getEdges());
              if (allMapChilds.length > 0) {
                allMapChilds.forEach(child => {
                  //if (child.id !== '2') {
                  const childEdge = {
                    id: `${childNodeId}=>${child.id}`,
                    source: childNodeId,
                    target: child.id,
                    data: { uid: 0, cessId: data.cessId },
                    type: 'step',
                    style: { stroke: '#680001', strokeDasharray: 3 },
                    markerEnd: {
                      type: MarkerType.Arrow,
                      color: '#680001',
                      height: 20,
                      width: 20,
                    },
                  };
                  allEdgesData.push(childEdge);
                  // setEdges((edges) =>
                  //   edges.concat([childEdge])
                  // );
                  //}
                });
              }
            }
          }
        }

        if (allEdgesData.length > 0) {
          let requestData: any[] = [];
          allEdgesData.forEach(edge => {
            let edgeData = {
              stepEdgeUid: 0,
              hiagramUid: userContext.hiagramId,
              cessUid: data.cessId,
              name: edge.id,
              isDeleted: false,
              dataManagedBy: userContext.userId
            }
            requestData.push(edgeData);
          });
          let edgeAPIData: any;
          edgeAPIData = await AddStepEdge(requestData);
          if (edgeAPIData !== null && edgeAPIData.data !== null && edgeAPIData.data === true) {
            let params = new URLSearchParams();
            params.append("huid", userContext.hiagramId.toString());
            params.append("cuid", data.cessId.toString());
            let listData: any;
            listData = await StepEdgeList(params);
            if (listData !== null && listData.data !== null && listData.data.data !== null) {
              let allEdgeListData = listData.data.data as any[];
              if (allEdgeListData.length > 0) {
                allEdgesData.forEach(edge => {
                  edge.data.uid = allEdgeListData.find((x) => x.name === edge.id).uid;
                });

                // let allNodes = [...getNodes(), childNode];
                setNodes((nodes) =>
                  nodes.concat([childNode])
                );
                //setNodes(allNodes);

                setEdges((edges) =>
                  edges.concat([...allEdgesData])
                );
                let removeEdgesCol: any[] = [];
                let allEdgeData = getEdges();
                //Remove all end edges
                const endNode = getNode('2');
                if (endNode) {
                  const allParentIds = getIncomers(endNode, getNodes(), getEdges()).map((node) => node.id);
                  if (allParentIds.length > 0) {
                    allParentIds.forEach(node => {
                      removeEdgesCol.push(allEdgeData.find((x) => x.id === `${node}=>2`));
                      setEdges((edges) =>
                        edges.filter((edge) => edge.id !== `${node}=>2`));
                    });
                  }
                }

                let addEdgesCol: any[] = [];
                //Connect to end node
                const allNodes = getNodes();
                if (allNodes.length > 0) {
                  allNodes.forEach(node => {
                    const allChilds = getOutgoers(node, getNodes(), getEdges());
                    if (allChilds.length === 0 && node.id !== '2') {
                      const endEdge = {
                        id: `${node.id}=>2`,
                        source: node.id,
                        target: '2',
                        data: { uid: 0, cessId: data.cessId },
                        type: 'step',
                        style: { stroke: '#680001', strokeDasharray: 3 },
                        markerEnd: {
                          type: MarkerType.Arrow,
                          color: '#680001',
                          height: 20,
                          width: 20,
                        },
                      };
                      addEdgesCol.push(endEdge);
                      setEdges((edges) =>
                        edges.concat([endEdge])
                      );
                    }
                  });
                }

                requestData = [];
                if (removeEdgesCol.length > 0) {
                  removeEdgesCol.forEach(edge => {
                    let edgeData = {
                      stepEdgeUid: edge.data.uid,
                      hiagramUid: userContext.hiagramId,
                      cessUid: data.cessId,
                      name: edge.id,
                      isDeleted: true,
                      dataManagedBy: userContext.userId
                    }
                    requestData.push(edgeData);
                  });
                }
                if (addEdgesCol.length > 0) {
                  addEdgesCol.forEach(edge => {
                    let edgeData = {
                      stepEdgeUid: 0,
                      hiagramUid: userContext.hiagramId,
                      cessUid: data.cessId,
                      name: edge.id,
                      isDeleted: false,
                      dataManagedBy: userContext.userId
                    }
                    requestData.push(edgeData);
                  });
                }
                if (requestData.length > 0) {
                  edgeAPIData = await AddStepEdge(requestData);
                  getAllStepEdges();
                }
              }
            }
          }

        }

        setOpenAddEditModal(false);
      }
      setLoading(false);
    }
    catch (error: any) {
      setLoading(false);
      if (error) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: error.response.message,
          alertType: AlertType.Error,
        });
      }
    }
  }

  const onAddAfterClick = () => {
    setAddEditModalTitle("Add step after");
    setFormInitial(formInitialValues);
    setOpenAddEditModal(true);
    setNodeAddType("ADD_AFTER");
    openCloseMenu();
  }

  async function addBeforeHook(title: any, description: any) {
    setLoading(true);
    let allEdgesData: any[] = [];
    try {
      const currentNode = getNode(id);
      if (!currentNode) {
        return;
      }

      const parentNodes = getIncomers(currentNode, getNodes(), getEdges());
      if (parentNodes.length <= 0) {
        return;
      }
      const parentNode = parentNodes[0];

      const childNodes = getOutgoers(parentNode, getNodes(), getEdges());
      if (childNodes.length <= 0) {
        return;
      }

      let requestData = {
        stepUid: 0,
        hiagramUid: userContext.hiagramId,
        cessUid: data.cessId,
        name: title,
        description: description,
        position: (parentNode.position.x + "," + (parentNode.position.y + 200)).toString(),
        isDeleted: false,
        dataManagedBy: userContext.userId
      }
      let stepdata: any;
      stepdata = await AddStep(requestData);

      if (stepdata !== null && stepdata.data !== null) {

        const insertNodeId = stepdata.data.toString();

        const insertNode = {
          id: insertNodeId,
          position: { x: parentNode.position.x, y: parentNode.position.y + 200 },
          data: { title: title, description: description, isMenuOpen: false, cessId: data.cessId, stakeCount: 0, hintCount: 0, assetCount: 0, docCount: 0 },
          type: 'step',
        };

        //Add Parent to new Node edge
        parentNodes.forEach(parent => {
          const sourceEdge = {
            id: `${parent.id}=>${insertNodeId}`,
            source: parent.id,
            target: insertNodeId,
            data: { uid: 0, cessId: data.cessId },
            type: 'step',
            style: { stroke: '#680001', strokeDasharray: 3 },
            markerEnd: {
              type: MarkerType.Arrow,
              color: '#680001',
              height: 20,
              width: 20,
            },
          };
          allEdgesData.push(sourceEdge);
          // setEdges((edges) =>
          //   edges.concat([sourceEdge])
          // );
        });

        //Add New node to child nodes edges
        childNodes.forEach(child => {
          const targetEdge = {
            id: `${insertNodeId}=>${child.id}`,
            source: insertNodeId,
            target: child.id,
            data: { uid: 0, cessId: data.cessId },
            type: 'step',
            style: { stroke: '#680001', strokeDasharray: 3 },
            markerEnd: {
              type: MarkerType.Arrow,
              color: '#680001',
              height: 20,
              width: 20,
            },
          };
          allEdgesData.push(targetEdge)
          // setEdges((edges) =>
          //   edges.concat([targetEdge])
          // );
        });

        if (allEdgesData.length > 0) {
          let requestData: any[] = [];
          allEdgesData.forEach(edge => {
            let edgeData = {
              stepEdgeUid: 0,
              hiagramUid: userContext.hiagramId,
              cessUid: data.cessId,
              name: edge.id,
              isDeleted: false,
              dataManagedBy: userContext.userId
            }
            requestData.push(edgeData);
          });

          let stepdata: any;
          stepdata = await AddStepEdge(requestData);
          if (stepdata !== null && stepdata.data !== null && stepdata.data === true) {
            let params = new URLSearchParams();
            params.append("huid", userContext.hiagramId.toString());
            params.append("cuid", data.cessId.toString());
            let listData: any;
            listData = await StepEdgeList(params);
            if (listData !== null && listData.data !== null && listData.data.data !== null) {
              let allEdgeListData = listData.data.data as any[];
              if (allEdgeListData.length > 0) {
                allEdgesData.forEach(edge => {
                  edge.data.uid = allEdgeListData.find((x) => x.name === edge.id).uid;
                });

                setNodes((nodes) =>
                  nodes.concat([insertNode])
                );

                setEdges((edges) =>
                  edges.concat([...allEdgesData])
                );

                let removeEdges: any[] = [];
                let allNodesForData = getEdges();
                //Remove edges
                parentNodes.forEach(parent => {
                  childNodes.forEach(child => {
                    let removeEdge = `${parent.id}=>${child.id}`;
                    removeEdges.push(allNodesForData.find((x) => x.id === removeEdge));
                    setEdges((edges) =>
                      edges.filter((edge) => edge.id !== removeEdge));
                  });
                });

                requestData = [];
                removeEdges.forEach(edge => {
                  let edgeData = {
                    stepEdgeUid: edge.data.uid,
                    hiagramUid: userContext.hiagramId,
                    cessUid: data.cessId,
                    name: edge.id,
                    isDeleted: true,
                    dataManagedBy: userContext.userId
                  }
                  requestData.push(edgeData);
                });
                stepdata = await AddStepEdge(requestData);
                getAllStepEdges();
              }
            }
          }
        }
        setOpenAddEditModal(false);
      }
      setLoading(false);
    }
    catch (error: any) {
      setLoading(false);
      if (error) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: error.response.message,
          alertType: AlertType.Error,
        });
      }
    }
  }

  const onAddBeforeClick = () => {
    setAddEditModalTitle("Add step before");
    setFormInitial(formInitialValues);
    setOpenAddEditModal(true);
    setNodeAddType("ADD_BEFORE");
    openCloseMenu();
  }

  async function addConcurrentHook(title: any, description: any) {
    setLoading(true);
    let allEdgesData: any[] = [];
    try {
      const currentNode = getNode(id);
      if (!currentNode) {
        return;
      }

      const parentIds = getIncomers(currentNode, getNodes(), getEdges()).map((node) => node.id);

      const parentNode = getNode(parentIds[0]);

      if (!parentNode) {
        return;
      }

      let requestData = {
        stepUid: 0,
        hiagramUid: userContext.hiagramId,
        cessUid: data.cessId,
        name: title,
        description: description,
        position: (parentNode.position.x + "," + (parentNode.position.y + 200)).toString(),
        isDeleted: false,
        dataManagedBy: userContext.userId
      }
      let stepdata: any;
      stepdata = await AddStep(requestData);

      if (stepdata !== null && stepdata.data !== null) {

        const concurrentNodeId = stepdata.data.toString();

        const concurrentNode = {
          id: concurrentNodeId,
          position: { x: parentNode.position.x, y: parentNode.position.y + 200 },
          type: 'step',
          data: { title: title, description: description, isMenuOpen: false, cessId: data.cessId }
        };

        const concurrentEdge = {
          id: `${parentNode.id}=>${concurrentNodeId}`,
          source: parentNode.id,
          target: concurrentNodeId,
          data: { uid: 0, cessId: data.cessId },
          type: 'step',
          style: { stroke: '#680001', strokeDasharray: 3 },
          markerEnd: {
            type: MarkerType.Arrow,
            color: '#680001',
            height: 20,
            width: 20,
          },
        };
        allEdgesData.push(concurrentEdge);

        // setNodes((nodes) =>
        //   nodes.concat([concurrentNode])
        // );

        // setEdges((edges) =>
        //   edges.concat([concurrentEdge])
        // );

        //Add edges to child    
        const childs = getOutgoers(currentNode, getNodes(), getEdges());
        if (childs.length > 0) {
          childs.forEach(child => {
            let childEdge = {
              id: `${concurrentNodeId}=>${child.id}`,
              source: concurrentNodeId,
              target: child.id,
              data: { uid: 0, cessId: data.cessId },
              type: 'step',
              style: { stroke: '#680001', strokeDasharray: 3 },
              markerEnd: {
                type: MarkerType.Arrow,
                color: '#680001',
                height: 20,
                width: 20,
              },
            }
            allEdgesData.push(childEdge);
            // setEdges((edges) =>
            //   edges.concat([childEdge])
            // );
          });
        }

        //Add edges to parent
        if (parentIds.length > 0) {
          parentIds.forEach(parentId => {
            if (parentId !== parentNode.id) {
              let parentEdge = {
                id: `${parentId}=>${concurrentNodeId}`,
                source: parentId,
                target: concurrentNodeId,
                data: { uid: 0, cessId: data.cessId },
                type: 'step',
                style: { stroke: '#680001', strokeDasharray: 3 },
                markerEnd: {
                  type: MarkerType.Arrow,
                  color: '#680001',
                  height: 20,
                  width: 20,
                },
              }
              allEdgesData.push(parentEdge);
              // setEdges((edges) =>
              //   edges.concat([parentEdge])
              // );
            }
          });
        }

        if (allEdgesData.length > 0) {
          let requestData: any[] = [];
          allEdgesData.forEach(edge => {
            let edgeData = {
              stepEdgeUid: 0,
              hiagramUid: userContext.hiagramId,
              cessUid: data.cessId,
              name: edge.id,
              isDeleted: false,
              dataManagedBy: userContext.userId
            }
            requestData.push(edgeData);
          });
          let stepdata: any;
          stepdata = await AddStepEdge(requestData);
          if (stepdata !== null && stepdata.data !== null && stepdata.data === true) {
            let params = new URLSearchParams();
            params.append("huid", userContext.hiagramId.toString());
            params.append("cuid", data.cessId.toString());
            let listData: any;
            listData = await StepEdgeList(params);
            if (listData !== null && listData.data !== null && listData.data.data !== null) {
              let allEdgeListData = listData.data.data as any[];
              if (allEdgeListData.length > 0) {
                allEdgesData.forEach(edge => {
                  edge.data.uid = allEdgeListData.find((x) => x.name === edge.id).uid;
                });

                setNodes((nodes) =>
                  nodes.concat([concurrentNode])
                );

                setEdges((edges) =>
                  edges.concat([...allEdgesData])
                );

                let removeEdgesCol: any[] = [];
                let allEdgeData = getEdges();
                //Remove all end edges
                const endNode = getNode('2');
                if (endNode) {
                  const allParentIds = getIncomers(endNode, getNodes(), getEdges()).map((node) => node.id);
                  if (allParentIds.length > 0) {
                    allParentIds.forEach(node => {
                      removeEdgesCol.push(allEdgeData.find((x) => x.id === `${node}=>2`));
                      setEdges((edges) =>
                        edges.filter((edge) => edge.id !== `${node}=>2`));
                    });
                  }
                }

                let addEdgesCol: any[] = [];
                //Connect to end node
                const allNodes = getNodes();
                if (allNodes.length > 0) {
                  allNodes.forEach(node => {
                    const allChilds = getOutgoers(node, getNodes(), getEdges());
                    if (allChilds.length === 0 && node.id !== '2') {
                      const endEdge = {
                        id: `${node.id}=>2`,
                        source: node.id,
                        target: '2',
                        data: { uid: 0, cessId: data.cessId },
                        type: 'step',
                        style: { stroke: '#680001', strokeDasharray: 3 },
                        markerEnd: {
                          type: MarkerType.Arrow,
                          color: '#680001',
                          height: 20,
                          width: 20,
                        },
                      };
                      addEdgesCol.push(endEdge);
                      setEdges((edges) =>
                        edges.concat([endEdge])
                      );
                    }
                  });
                }

                requestData = [];
                if (removeEdgesCol.length > 0) {
                  removeEdgesCol.forEach(edge => {
                    let edgeData = {
                      stepEdgeUid: edge.data.uid,
                      hiagramUid: userContext.hiagramId,
                      cessUid: data.cessId,
                      name: edge.id,
                      isDeleted: true,
                      dataManagedBy: userContext.userId
                    }
                    requestData.push(edgeData);
                  });
                }
                if (addEdgesCol.length > 0) {
                  addEdgesCol.forEach(edge => {
                    let edgeData = {
                      stepEdgeUid: 0,
                      hiagramUid: userContext.hiagramId,
                      cessUid: data.cessId,
                      name: edge.id,
                      isDeleted: false,
                      dataManagedBy: userContext.userId
                    }
                    requestData.push(edgeData);
                  });
                }
                if (requestData.length > 0) {
                  stepdata = await AddStepEdge(requestData);
                  getAllStepEdges();
                }
              }
            }
          }
        }
        setOpenAddEditModal(false);
      }
      setLoading(false);
    }
    catch (error: any) {
      setLoading(false);
      if (error) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: error.response.message,
          alertType: AlertType.Error,
        });
      }
    }
  }

  const onAddConcurrentClick = () => {
    setAddEditModalTitle("Add concurrent step");
    setFormInitial(formInitialValues);
    setOpenAddEditModal(true);
    setNodeAddType("ADD_CONCURRENT");
    openCloseMenu();
  }

  const onEditStep = () => {
    const currentNode = getNode(id);
    if (currentNode) {
      setAddEditModalTitle("Edit step");
      const formValues = {
        answerId: 0,
        description: currentNode.data.description,
        title: currentNode.data.title,
        stepId: 0
      };
      setFormInitial(formValues);
      setOpenAddEditModal(true);
      setNodeAddType("EDIT");
      openCloseMenu();
    }
  }

  const openCloseMenu = () => {
    setMenu();
    SelectNode();
  };

  useOnSelectionChange({
    onChange: ({ nodes, edges }) => {
      if (nodes.length === 0) {
        setCloseMenu();
      }
    },
  });

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleCloseAddEditModal = () => {
    setFormInitial(formInitialValues);
    setOpenAddEditModal(false);
  };

  const initMenuItems = [
    { id: 1, label: 'Snow', iconSrc: "" },
  ];
  const rowsSatatic = [
    { id: 2, number: 4, iconSrc: "" },
  ];

  async function updateNodeData(values: any) {
    setLoading(true);
    try {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === id) {
            node.data = {
              ...node.data,
              title: values.title,
              description: values.description,
            };
          }
          return node;
        })
      );
      let nodeData: any = getNode(id);
      let requestData = {
        stepUid: nodeData.id,
        hiagramUid: userContext.hiagramId,
        cessUid: data.cessId,
        name: values.title,
        description: values.description,
        position: (nodeData.position.x + "," + (nodeData.position.y)).toString(),
        isDeleted: false,
        dataManagedBy: userContext.userId
      }
      let nodeAPIdata: any;
      nodeAPIdata = await UpdateStep(requestData);
      setOpenAddEditModal(false);
      setLoading(false);
    }
    catch (error: any) {
      setLoading(false);
      if (error) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: error.response.message,
          alertType: AlertType.Error,
        });
      }
    }
  }

  function submitForm(values: any, resetForm: any) {
    if (nodeAddType === "ADD_AFTER") {
      addAfterHook(values.title, values.description);
    } else if (nodeAddType === "ADD_BEFORE") {
      addBeforeHook(values.title, values.description);
    } else if (nodeAddType === "ADD_CONCURRENT") {
      addConcurrentHook(values.title, values.description);
    } else if (nodeAddType === "EDIT") {
      updateNodeData(values);
    }
    resetForm();
  }

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip describeChild={true} {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#fff',
      color: 'rgba(0, 0, 0, 0.87)',
      //width: '400px',
      minWidth: 500,
      maxWidth: 500,
      fontSize: theme.typography.pxToRem(14),
      borderRadius: '10px',
      boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
      //border: '1px solid #dadde9',
    },
  }));

  return (
    //NEW CODE
    <>
      <div className={cx(styles.stepnodebox)}>
        {data.isMenuOpen ?
          <Paper sx={{ position: 'absolute', backgroundColor: '#fff', bottom: 55, right: 0, width: '140px', borderRadius: '10px 10px 0px 10px', zIndex: 99999 }}>
            <MenuList>
              <MenuItem onClick={onAddAfterClick} >
                <Box sx={{ display: 'flex', }}>
                  <img loading="lazy" src={Add} style={{ height: 'auto', width: '10px' }} alt="imprend" />
                  <Typography fontSize={10} sx={{ paddingLeft: '5px' }}>
                    Add step after
                  </Typography>
                </Box>
              </MenuItem>
              <MenuItem onClick={onAddBeforeClick}>
                <Box sx={{ display: 'flex', }}>
                  <img loading="lazy" src={Add} style={{ height: 'auto', width: '10px' }} alt="imprend" />
                  <Typography fontSize={10} sx={{ paddingLeft: '5px' }}>
                    Add step before
                  </Typography>
                </Box>
              </MenuItem>
              <MenuItem onClick={onAddConcurrentClick}>
                <Box sx={{ display: 'flex', }}>
                  <img loading="lazy" src={Add} style={{ height: 'auto', width: '10px' }} alt="imprend" />
                  <Typography fontSize={10} sx={{ paddingLeft: '5px' }}>
                    Add concurrent step
                  </Typography>
                </Box>
              </MenuItem>
              <MenuItem onClick={onEditStep}>
                <Box sx={{ display: 'flex', }}>
                  <img loading="lazy" src={Edit} style={{ height: 'auto', width: '10px' }} alt="imprend" />
                  <Typography fontSize={10} sx={{ paddingLeft: '5px' }}>
                    Edit step
                  </Typography>
                </Box>
              </MenuItem>
              <MenuItem onClick={onDeleteClick}>
                <Box sx={{ display: 'flex', }}>
                  <img loading="lazy" src={DeleteIcon} style={{ height: 'auto', width: '10px' }} alt="imprend" />
                  <Typography fontSize={10} sx={{ paddingLeft: '5px' }}>
                    Delete step
                  </Typography>
                </Box>
              </MenuItem>
            </MenuList>
          </Paper>
          : <></>}
        <HtmlTooltip
          title={
            <React.Fragment>
              {/* <Box
                sx={{
                  pointerEvents: 'auto',
                  cursor:'text',
                  padding: '15px',
                  width: '400px',
                  borderRadius: '10px',
                  zIndex: 99999,
                  boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
                }}
            //onMouseLeave={handlePopoverClose}
          > */}
              <Typography onClick={() => navigator.clipboard.writeText(currentNode.data.title)} sx={{ p: 1, cursor: 'copy' }}><b>Name:</b> {currentNode.data.title}</Typography>
              <Typography onClick={() => navigator.clipboard.writeText(currentNode.data.description)} sx={{ p: 1, cursor: 'copy' }}><b>Description:</b> {currentNode.data.description}</Typography>
              {/* </Box> */}
            </React.Fragment>
          }
        >
          <Card
            className={cx(styles.stepcomponent)}
            sx={{ borderRadius: '10px 0px 10px 10px' }}
            elevation={0}
            onClick={SelectNode}
          //onMouseEnter={(e) => handlePopoverOpen(e)}

          >
            <Card className={cx(styles.stepdetail)} sx={{ backgroundColor: "#2C2449", borderRadius: '0px' }}>
              <Grid container sx={{ padding: '5px' }}>
                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px', height: '45px' }}>
                  <Typography className={cx(styles.stepiconbox)}>
                    <img loading="lazy" className={cx(styles.stepicon)} src={Process} alt="imprend" />
                  </Typography>
                </Grid>
                <Grid item xs={9} className={cx(styles.stepdetailright)} >
                  <Typography className={cx(styles.steptext)} sx={{ paddingLeft: "5px", width: "160px", maxHeight: "34px", overflow: "hidden", textOverflow: "ellipsis", fontFamily: 'Roboto' }} >
                    {data.title}
                  </Typography>
                  {/* {data.isManuAccess === true && userContext.isEditAccess === true ? */}
                  {/* {data.isManuAccess === true ? */}
                  {userContext.isEditAccess === true ?
                    <Typography
                      className={cx(styles.stepmenu)}
                      onClick={openCloseMenu}
                    >
                      <MoreVertIcon sx={{ color: "#ffffff" }} />
                    </Typography> : <></>}
                </Grid>
              </Grid>
            </Card>
            <Card className={cx(styles.stepdetailcount)} hidden>
              <>
                <Grid container sx={{ marginTop: '3px' }}>
                  <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} xs={3}>
                    <img loading="lazy" style={{ height: 'auto', width: '15px' }} src={AssistIcon} alt="imprend" />
                    <Typography sx={{ fontSize: '8px', paddingLeft: '5px' }} >
                      {data.assetCount}
                    </Typography>
                  </Grid>
                  <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} xs={3}>
                    <img loading="lazy" style={{ height: 'auto', width: '15px' }} src={DocIcon} alt="imprend" />
                    <Typography sx={{ fontSize: '8px', paddingLeft: '5px' }} >
                      {data.docCount}
                    </Typography>
                  </Grid>
                  <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} xs={3}>
                    <img loading="lazy" style={{ height: 'auto', width: '15px' }} src={HintIcon} alt="imprend" />
                    <Typography sx={{ fontSize: '8px', paddingLeft: '5px' }} >
                      {data.hintCount}
                    </Typography>
                  </Grid>
                  <Grid item sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} xs={3}>
                    <img loading="lazy" style={{ height: 'auto', width: '15px' }} src={StakeIcon} alt="imprend" />
                    <Typography sx={{ fontSize: '8px', paddingLeft: '5px' }} >
                      {data.stakeCount}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            </Card>
          </Card >
        </HtmlTooltip>
        <Handle className={styles.handle} type="target" position={Position.Top} isConnectable={false} />
        <Handle className={styles.handle} type="source" position={Position.Bottom} isConnectable={false} />

      </div>
      <Dialog
        open={openAddEditModal}
        fullWidth={true}
        keepMounted
        maxWidth={"md"}
        //onClose={handleCloseAddEditModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='delete-client-dialog-box'
      >
        <DialogTitle sx={{ fontSize: '20px', fontWeight: 'bold' }}>{addEditModalTitle}</DialogTitle>
        <DialogContent>
          <Formik
            enableReinitialize
            initialValues={forminitial}
            validationSchema={formValidation}
            onSubmit={(values: any, { resetForm }) => {
              submitForm(values, resetForm);
            }}
          >{(props: any) => {
            return (
              <>
                <Form onSubmit={props.handleSubmit} noValidate>
                  <Grid container spacing={2} sx={{ padding: '5px' }}>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                      <Typography>
                        Step Title
                      </Typography>
                    </Grid>
                    <Grid item xs={10} sx={{ position: 'relative' }}>
                      <TextField
                        id="title"
                        // label="Step Title"
                        autoFocus
                        inputProps={{ maxLength: 90 }}
                        fullWidth
                        // placeholder="Contrary to popular belief"
                        placeholder="Step Title"
                        onChange={props.handleChange.bind("title")}
                        onBlur={() => setFocusedName(false)}
                        value={props.values.title}
                        onFocus={() => setFocusedName(true)}
                        className={cx(styles.stepTextFields)}
                        error={
                          props.values.title === "" && props.errors.title && props.touched.title
                            ? true
                            : false
                        }
                      >
                      </TextField>
                      {isFocusedName && <Typography sx={{ position: 'absolute', right: '0px' }}>{(props.values.title !== null ? props.values.title.length : 0) + "/" + 90}</Typography>}
                      {props.errors.title && props.touched.title ?
                        <Typography className="validation-message" >
                          {props.errors.title}
                        </Typography> : <></>}
                    </Grid>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'start', justifyContent: 'end', marginTop: '15px' }}>
                      <Typography sx={{ marginTop: '5px' }}>
                        Description
                      </Typography>
                    </Grid>
                    <Grid item xs={10} sx={{ marginTop: '15px', position: 'relative' }}>
                      <TextField
                        id="description"
                        // label="Description"
                        inputProps={{ maxLength: 2000 }}
                        fullWidth
                        className={cx(styles.stepTextFields)}
                        multiline={true}
                        rows={3}
                        placeholder="Description"
                        onChange={props.handleChange.bind("description")}
                        onBlur={() => setFocusedDescription(false)}
                        value={props.values.description}
                        onFocus={() => setFocusedDescription(true)}

                      >
                      </TextField>
                      {isFocusedDescription && <Typography sx={{ position: 'absolute', right: '0px' }}>{(props.values.description !== null ? props.values.description.length : 0) + "/" + 2000}</Typography>}
                    </Grid>

                    <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                      <Button
                        variant='outlined'
                        onClick={() => {
                          props.resetForm();
                          handleCloseAddEditModal();
                        }}
                        className="popup-btn"
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={6} >
                      <LoadingButton
                        variant='contained'
                        //onClick={deleteNode}
                        type='submit'
                        className={cx(styles.stepPopupBtn)}
                        // className="popup-btn"
                        loading={loading}
                        startIcon={nodeAddType === "EDIT" ? <></> : !loading ? <AddIcon /> : <AddIcon sx={{ color: "transparent" }} />}
                      >
                        {nodeAddType === "EDIT" ? "Save" : "Add"}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              </>
            );
          }}
          </Formik>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='delete-client-dialog-box'
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className='popup-text'>
            Are you sure you want to delete this Step node? Any further Steps that rely on this connection (child-nodes) will also be deleted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant='outlined'
            onClick={deleteNodeEdges}
            className="popup-btn"
            loading={deleteLoading}
          >
            Yes
          </LoadingButton>
          <Button
            variant='contained'
            onClick={handleCloseDeleteModal}
            // className="popup-btn"
            className={cx(styles.stepPopupBtn)}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default memo(StepNode);

export class Context {
    token: string = "";

    //User
    userId: number = 0;
    userKey: string = "";
    userRole: string = "";
    userRoleId: string = "";
    firstName: string = "";
    lastName: string = "";
    fullName: string = "";
    email: string = "";
    emailConfirmed: boolean = false;
    firstLogin: boolean = false;

    //Client
    clientId: number = 0;
    clientOrgName: string = "";
    clientAddress: string = "";
    clientContactNumber: string = "";
    clientIsFree: boolean = false;

    //Subscriptions
    subscriptions: any[] = [];

    //Hiagram
    hiagramId: number = 0;
    hiagramType: string = "";
    hiagramName: string = "";
    hiagramProgress: number = 0;
    totalTeamProj: number = 0;
    totalRole: number = 0;
    teamProjName: string = "";
    teamProjId: number = 0;
    teamProjPurpose: string = "";
    teamProjDescription: string = "";
    isProj: string = "false";
    roleId: number = 0;
    roleName: string = "";
    activityId: number = 0;
    activityName: string = "";
    activityDescription: string = "";
    cessId: number = 0;
    cessName: string = "";
    stepId: number = 0;
    stepName: string = "";
    lastQuestionInfo: any;
    isComeFromHiagram: boolean = false;
    tempUid: number = 0;
    isEditAccess: boolean = false;
    isDataUpdated: boolean = false;
    isItemCountUpdated: boolean = false;
    isCardCountUpdate: boolean = false;
    isAlert: boolean = false;
    alertMessage: string = "";
    alertType: string = "success";

    isLoadding: boolean = false;
    isMenuVisible: boolean = false;

    tokenExpiryTime: string = "";

    isThemeDark: boolean = false;

    previousQuestion:any;

};